import { GetBlocksResponse } from '@/gql/block/types';
import { GetBlockEventResponse } from '@/gql/blockEvent/types';
import { keys } from '@/gql/global/keys';
import { queryClient } from '@/services/graphql/queryClient';
import { Block } from '@/types/block';

export function getBlockById(blocks: Block[], blockId: string) {
  return blocks.find((block) => block.id === blockId);
}

export function deleteBlockCache(blockId: string) {
  queryClient.setQueryData<GetBlocksResponse>(keys.blocks.all.queryKey, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      block: state.block.filter((block) => block.id !== blockId),
    };
  });

  queryClient.setQueriesData<GetBlockEventResponse>(keys.blockEvents.list._def, (state) => {
    if (state == null) {
      return state;
    }

    return {
      ...state,
      blockEvent: state.blockEvent.filter((item) => item.blockId !== blockId),
    };
  });
}
