import ScrollRestoration from '@/components/ScrollRestoration';
import StenoAiChatbot from '@/components/StenoAiChatbot';
import { AnimatePlanPagesProvider } from '@/contexts/AnimatedPlanPages';
import { FeatureFlagProvider } from '@/contexts/FeatureFlag';
import SoundEffectsProvider from '@/contexts/SoundEffects';
import { queryClient } from '@/services/graphql/queryClient';
import GlobalContainer from '@/theme/Global';
import '@/utils/fix-date';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import './sentry.config';
import theme from './theme';
import { initTracking } from './utils/tracking';

const { ToastContainer } = createStandaloneToast({ theme });

function App() {
  // amplitude tracking
  useEffect(() => initTracking(), []);

  return (
    <HelmetProvider>
      <StenoAiChatbot />
      <ChakraProvider resetCSS theme={theme}>
        {/* Uncomment to force dark mode even if user had saved light mode preference in localStorage */}
        {/* TODO: light mode is buggy and unfinished but is enabled temproraily by the request of the client */}
        {/* <DarkMode> */}
        <QueryClientProvider client={queryClient}>
          <FeatureFlagProvider environment={import.meta.env.VITE_ENV}>
            <GlobalContainer />
            <ToastContainer />
            <BrowserRouter>
              <SoundEffectsProvider>
                <AnimatePlanPagesProvider>
                  <ScrollRestoration>
                    <AppRoutes />
                  </ScrollRestoration>
                </AnimatePlanPagesProvider>
              </SoundEffectsProvider>
            </BrowserRouter>

            {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />}

            {/* {(import.meta.env.VITE_ENV === 'uat' || import.meta.env.VITE_ENV === 'staging') && <EnvRibbon />} */}
          </FeatureFlagProvider>
        </QueryClientProvider>
        {/* </DarkMode> */}
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
