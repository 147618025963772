import { RoutesList } from '@/routes/routesList';
import { pageVariantsFadeInOut } from '@/utils/pageAnimation';
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const pagesNew = {
  [RoutesList.HomePage]: 0,
  [RoutesList.WeeklyCapturePage]: 0,
  [RoutesList.MyPlanPage]: 1,
  [RoutesList.MyPlanDailyPage]: 1,
  [RoutesList.SchedulePage]: 1,
  [RoutesList.CelebratePage]: 0,
  [RoutesList.PeoplePage]: 0,
  [RoutesList.ProjectsPage]: 0,
  [RoutesList.CategoryManagerPage]: 0,
};

interface AnimationDirection {
  pageNavigationEffect:
    | {
        initial: {
          opacity: number;
          x?: string | number;
        };
        in: {
          opacity: number;
          x?: string | number;
        };
        out: {
          opacity: number;
          x?: string | number;
        };
      }
    | undefined;
  setCurrentIndex: (val: number) => void;
}

interface AnimatePlanPagesProviderProps {
  children: ReactNode;
}

const AnimatePlanPagesContext = createContext<AnimationDirection>({
  pageNavigationEffect: undefined,
  setCurrentIndex: () => null,
});

const AnimatePlanPagesProvider = ({ children }: AnimatePlanPagesProviderProps) => {
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(pagesNew[location.pathname]);
  const oldIndexRef = useRef(pagesNew[location.pathname]);

  useEffect(() => {
    oldIndexRef.current = currentIndex;
  }, [currentIndex]);

  return (
    <AnimatePlanPagesContext.Provider
      value={{
        pageNavigationEffect: currentIndex !== 0 && oldIndexRef.current !== 0 ? undefined : pageVariantsFadeInOut,
        setCurrentIndex,
      }}
    >
      {children}
    </AnimatePlanPagesContext.Provider>
  );
};

const useAnimatePlanPages = (): AnimationDirection => {
  const context = useContext(AnimatePlanPagesContext);

  if (!context) {
    throw new Error('useAnimatePlanPages should be used within a AnimatePlanPagesProvider.');
  }

  return context;
};

export { AnimatePlanPagesProvider, AnimatePlanPagesContext, useAnimatePlanPages };
