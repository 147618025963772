import { getCalendarDayTextColor } from '@/components/MyPlanStep/WeeklyCalendar';
import Body from '@/components/WeeklyCalendar/Body';
import Header from '@/components/WeeklyCalendar/Header';
import { Category } from '@/types/category';
import { ActionEventType } from '@/types/myPlan';
import rem from '@/utils/rem';
import { Flex, HStack, Table, TableContainer, Tr } from '@chakra-ui/react';
import { isToday } from 'date-fns';
import { Children } from 'react';

type HeaderType = {
  weekDay: string;
  monthDay: string;
  dateDay: Date;
};

export type EventsPerDayType = {
  events: ActionEventType[];
  day: string;
};

export type DataType = {
  category: Category;
  events: Record<string, EventsPerDayType>;
};

export type CalendarEventType = {
  header: HeaderType[];
  data: DataType;
};

type Props = {
  eventsList: CalendarEventType;
  onClickEvent: (eventId: string) => void;
};

const LAST_DAY_OF_WEEK_INDEX = 6;

function WeeklyCalendar({ eventsList, onClickEvent }: Props) {
  const { data, header } = eventsList;
  const { category, events } = data;

  return (
    <TableContainer width="full" paddingBottom={rem(32)}>
      <Table
        position="relative"
        width="full"
        height="inherit"
        __css={{
          borderCollapse: 'separate',
          borderSpacing: '0',
          width: '100%',
        }}
      >
        <Header>
          {Children.toArray(
            header.map((header) => (
              <Header.Cell
                paddingTop="0"
                textTransform="capitalize"
                borderBottomWidth={isToday(header.dateDay) ? rem(1) : '0'}
                borderBottomStyle="solid"
                borderBottomColor="cyan.500"
                stackProps={{
                  alignItems: 'center',
                }}
              >
                <HStack
                  textStyle="xsmall"
                  justifyContent="center"
                  fontWeight={isToday(new Date(header.dateDay)) ? 900 : 500}
                >
                  <Header.WeekDay color={getCalendarDayTextColor(header.dateDay)}>
                    {`${header.weekDay} ${header.monthDay}`}
                  </Header.WeekDay>
                </HStack>
              </Header.Cell>
            )),
          )}
        </Header>

        <Body>
          <Tr height={rem(125)}>
            {Object.entries(events).map(([key, { events }], index) => (
              <Body.Cell
                key={key}
                categoryInfo={category}
                sx={{
                  borderTopLeftRadius: index === 0 ? rem(4) : 0,
                  borderBottomLeftRadius: index === 0 ? rem(4) : 0,
                  borderTopRightRadius: index === LAST_DAY_OF_WEEK_INDEX ? rem(4) : 0,
                  borderBottomRightRadius: index === LAST_DAY_OF_WEEK_INDEX ? rem(4) : 0,
                }}
                opacity={1}
              >
                <Flex flexDirection="column" gap={rem(8)} height="100%" paddingX={rem(4)}>
                  {events.map((event) => (
                    <Body.WeeklyEventItem event={event} onClickEvent={(e) => onClickEvent(e.id)} cursor="pointer" />
                  ))}
                </Flex>
              </Body.Cell>
            ))}
          </Tr>
        </Body>
      </Table>
    </TableContainer>
  );
}

export default WeeklyCalendar;
