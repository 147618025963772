import RPMDateTimePicker from '@/components/RPMDateTimePicker/RPMDateTimePicker';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconChevronLeft, IconChevronRight } from '@/theme/icons';
import { isThisWeek } from '@/utils/calendar';
import { getDatePickerBorderColor } from '@/utils/color';
import rem from '@/utils/rem';
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackProps,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { addDays, addWeeks, isToday, subDays, subWeeks } from 'date-fns';
import { useCallback } from 'react';
import { Navigate } from 'react-big-calendar';

import InnerDate from './innerDate';

type Props = {
  selectedDate: Date;
  showCalendarIcon?: boolean;
  showDatepicker?: boolean;
} & StackProps;

const DayWeekNavigator = ({ selectedDate, showCalendarIcon, showDatepicker = false, ...stackProps }: Props) => {
  const updateSelectedDate = useCalendarMonthlyStore((state) => state.updateSelectedDate);
  const isDailyPlanPage = useIsDailyPlanPage();
  const isCurrentWeek = isThisWeek(selectedDate);
  const isCurrentDay = isToday(selectedDate);
  const isCurrent = (!isDailyPlanPage && isCurrentWeek) || (isDailyPlanPage && isCurrentDay);
  const borderColor = getDatePickerBorderColor(selectedDate, isCurrent);

  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker-secondary-navigation' });

  const onHandlePrevNextCalendarNavigation = useCallback(
    (date: Date, action: 'PREV' | 'NEXT' | 'TODAY') => {
      switch (action) {
        case Navigate.PREVIOUS: {
          const newDate = !isDailyPlanPage ? subWeeks(date, 1) : subDays(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        case Navigate.NEXT: {
          const newDate = !isDailyPlanPage ? addWeeks(date, 1) : addDays(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        default: {
          updateSelectedDate(date);
          return date;
        }
      }
    },
    [updateSelectedDate, isDailyPlanPage],
  );

  const onSelectedDate = useCallback(
    (selectedDate: Date) => {
      updateSelectedDate(selectedDate);
      onDatePickerClose();
    },
    [onDatePickerClose, updateSelectedDate],
  );

  if (showDatepicker) {
    return (
      <HStack
        position="relative"
        justifyContent="center"
        gap={rem(5)}
        width="fit-content"
        minWidth="fit-content"
        height="full"
        {...stackProps}
      >
        <Tooltip label={!isDailyPlanPage ? 'Previous week' : 'Previous day'} placement="top">
          <IconButton
            width={rem(32)}
            minWidth={0}
            height="full"
            margin={0}
            padding={0}
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            aria-label={!isDailyPlanPage ? 'Previous week' : 'Previous day'}
            backgroundColor="background-primary"
            icon={<Box as={IconChevronLeft} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.PREVIOUS)}
            size="xs"
            variant="ghost"
          />
        </Tooltip>

        <Popover closeOnBlur closeOnEsc isLazy isOpen={isDatePickerOpen} onClose={onDatePickerClose} placement="bottom">
          <PopoverTrigger>
            <InnerDate
              selectedDate={selectedDate}
              showCalendarIcon={showCalendarIcon}
              showDatePicker={showDatepicker}
              onDatePickerOpen={onDatePickerOpen}
              borderColor={borderColor}
            />
          </PopoverTrigger>
          <PopoverContent
            width={rem(400)}
            padding={rem(16)}
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            backgroundColor="background-primary"
          >
            <PopoverBody className={!isDailyPlanPage ? 'week-style' : 'day-style'} padding={0}>
              <RPMDateTimePicker
                dateRange={!isDailyPlanPage}
                selectedDate={selectedDate}
                updateSelectedDate={onSelectedDate}
                withActionButtons={false}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>

        <Tooltip label={!isDailyPlanPage ? 'Next week' : 'Next day'} placement="top">
          <IconButton
            width={rem(32)}
            minWidth={0}
            height="full"
            margin={0}
            padding={0}
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="stroke-primary"
            borderRadius={rem(4)}
            aria-label={!isDailyPlanPage ? 'Next week' : 'Next day'}
            backgroundColor="background-primary"
            icon={<Box as={IconChevronRight} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.NEXT)}
            size="xs"
            variant="ghost"
          />
        </Tooltip>
      </HStack>
    );
  }

  return (
    <HStack
      position="relative"
      justifyContent="center"
      gap={rem(5)}
      width="fit-content"
      minWidth="fit-content"
      height="full"
      {...stackProps}
    >
      <Tooltip label={!isDailyPlanPage ? 'Previous week' : 'Previous day'} placement="top">
        <IconButton
          width={rem(32)}
          minWidth={0}
          height="full"
          margin={0}
          padding={0}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          aria-label={!isDailyPlanPage ? 'Previous week' : 'Previous day'}
          aspectRatio={1}
          backgroundColor="background-primary"
          icon={<Box as={IconChevronLeft} />}
          onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.PREVIOUS)}
          size="xs"
          variant="ghost"
        />
      </Tooltip>

      <InnerDate
        selectedDate={selectedDate}
        showCalendarIcon={showCalendarIcon}
        showDatePicker={showDatepicker}
        borderColor={borderColor}
      />

      <Tooltip label={!isDailyPlanPage ? 'Next week' : 'Next day'} placement="top">
        <IconButton
          width={rem(32)}
          minWidth={0}
          height="full"
          margin={0}
          padding={0}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          aria-label={!isDailyPlanPage ? 'Next week' : 'Next day'}
          aspectRatio={1}
          backgroundColor="background-primary"
          icon={<Box as={IconChevronRight} />}
          onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.NEXT)}
          size="xs"
          variant="ghost"
        />
      </Tooltip>
    </HStack>
  );
};

export default DayWeekNavigator;
