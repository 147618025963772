import useIsDarkMode from '@/hooks/useIsDarkMode';
import { ProjectBgImage } from '@/theme/StaticImages';
import { IconPlus } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, Button, Icon, Text, VStack } from '@chakra-ui/react';

const NAV_HEIGHT = rem(96);

const opaciedBlue = getColorFromToken('blue.1000', '0.80');
const opaciedGray = getColorFromToken('gray.50', '0.80');

type Props = {
  onCreateProjectClick: () => void;
};

function NoProjects({ onCreateProjectClick }: Props) {
  const isDarkMode = useIsDarkMode();
  const gradientColor = isDarkMode ? opaciedBlue : opaciedGray;

  return (
    <Box
      height={`calc(100vh - ${NAV_HEIGHT})`}
      background={`linear-gradient(180deg, rgba(0, 0, 0, 0.00) 48.96%, ${gradientColor} 100%), linear-gradient(0deg, ${gradientColor} 0%, ${gradientColor} 100%), url(${ProjectBgImage})`}
      backgroundSize="cover"
    >
      <VStack
        justifyContent="center"
        gap={rem(32)}
        height="100%"
        textAlign="center"
        paddingX={{ base: rem(16), lg: rem(144) }}
      >
        <Text as="h2" textStyle="h2BarSB" color="text-tertiary" textTransform="uppercase">
          The canvas is blank, and it's waiting for your masterpiece!
        </Text>
        <Text as="h5" textStyle="h5SatMedium" color="text-primary">
          While you haven't initiated any projects yet, this is your golden opportunity to dive in, create, and shape
          the destiny you envision. Let's embark on this transformative journey!
        </Text>
        <Button
          leftIcon={<Icon as={IconPlus} width={rem(16)} height={rem(16)} />}
          onClick={onCreateProjectClick}
          paddingX={rem(32)}
          paddingY={rem(16)}
          variant="primary"
        >
          Create Project
        </Button>
      </VStack>
    </Box>
  );
}

export default NoProjects;
