import CaptureList from '@/components/CaptureList';
import CelebrateChart from '@/components/CelebrateChart';
import CompletedBlockStatic from '@/components/CompletedBlockStatic';
import ConfettiCelebration from '@/components/Confetti';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import Hero from '@/components/Hero';
import { COMPLETE } from '@/constants/action';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { GET_COMPLETED_ACTIONS_BLOCKS } from '@/gql/actions';
import { actionsKeys } from '@/gql/actions/keys';
import { CompletedActionsAndBlocks } from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import DashboardLayout from '@/layouts/Dashboard';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useUpdateWeeklyPlanByPk, useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { HeroCelebrate } from '@/theme/StaticImages';
import { IconGrowth, IconStarFull } from '@/theme/icons';
import theme from '@/theme/index';
import { Action } from '@/types/actions';
import { getActionsByCategoryId, getCompletedActions, getDailyActions, getWeeklyActions } from '@/utils/action';
import { filterActionsSameDay, formatDateToString, startOfTheWeek } from '@/utils/calendar';
import { convertDurationToMinutes } from '@/utils/index';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { trackReflectionEntered } from '@/utils/tracking';
import {
  Box,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  Grid,
  HStack,
  List,
  Spinner,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { motion } from 'framer-motion';
import { filter, flattenDeep, groupBy, isEmpty, map, orderBy, reduce, some, uniqBy } from 'lodash';
import { Children, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';
import { useWindowSize } from 'usehooks-ts';
import { z } from 'zod';

const chartFilters = [
  {
    value: 0,
    label: <Box as={IconGrowth} />,
  },
  {
    value: 1,
    label: <Box as={IconStarFull} />,
  },
];

const ReflectionsFormSchema = z.object({
  reflection: z.string().optional(),
});

type FormData = z.infer<typeof ReflectionsFormSchema>;

function CelebratePage() {
  const queryClient = useQueryClient();
  const chartRef = useRef<HTMLDivElement>(null);
  const { width: fullWindowWidth } = useWindowSize();
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const { data: weeklyPlanId, reflection: weeklyPlanReflections } = useWeeklyPlanId(selectedDate);
  const scheduledDate = addDays(startOfDay(selectedDate), 1);
  const startDate = formatDateToString(startOfTheWeek(selectedDate));
  const date = formatDateToString(selectedDate);

  const { pageNavigationEffect } = useAnimatePlanPages();
  const isDailyPlanPage = useIsDailyPlanPage();

  const updateWeeklyPlanReflections = useUpdateWeeklyPlanByPk(selectedDate);

  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      reflection: '',
    },
    resolver: zodResolver(ReflectionsFormSchema),
  });

  const previousReflectionValue = getValues('reflection');

  const { data: categoriesList } = useCategories();

  const { data: completedActionsAndBlock, isLoading: completedActionsAndBlockLoading } = useQuery({
    queryKey: [actionsKeys.actionsAndBlock, selectedDate, weeklyPlanId],

    queryFn: () => {
      const dailyPlanWhere = {
        scheduledDate: {
          _gte: addDays(startOfDay(selectedDate), -1),
          _lte: addDays(endOfDay(selectedDate), 1),
        },
      };

      const weeklyPlanWhere = {
        weeklyPlanId: {
          _eq: weeklyPlanId,
        },
      };

      return fetchData<CompletedActionsAndBlocks>(GET_COMPLETED_ACTIONS_BLOCKS, {
        whereActions: {
          category: { id: { _isNull: false } },
          ...(isDailyPlanPage ? dailyPlanWhere : weeklyPlanWhere),
        },
        whereBlock: {
          actions: {
            progressStatus: { _eq: COMPLETE },
            ...(isDailyPlanPage
              ? {
                  scheduledDate: {
                    _eq: scheduledDate,
                  },
                }
              : {
                  ...weeklyPlanWhere,
                }),
          },
        },
      });
    },

    enabled: !!weeklyPlanId,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  const allActions = useMemo(
    () =>
      isDailyPlanPage && completedActionsAndBlock?.action
        ? filterActionsSameDay(completedActionsAndBlock?.action, selectedDate)
        : completedActionsAndBlock?.action,
    [completedActionsAndBlock?.action, selectedDate, isDailyPlanPage],
  );

  const completedBlocks = useMemo(
    () => completedActionsAndBlock?.block?.filter((block) => block.isCompleted),
    [completedActionsAndBlock?.block],
  );

  const completedActions = useMemo(
    () => filter(allActions, (el) => el.progressStatus === COMPLETE /* && el.blockId === null*/),
    [allActions],
  );

  const completedActionsInBlocks = useMemo(
    () => filter(allActions, (el) => el.progressStatus === COMPLETE && !el.block?.isCompleted),
    [allActions],
  );

  const groupedData = useMemo(() => groupBy(allActions, 'category.name') as { [key: string]: Action[] }, [allActions]);

  const categoriesInfo = useMemo(() => {
    const catsInfo = completedActions.map((action) => {
      const category = action.category;
      const allActionsPerCat = filter(allActions, (el) => el.category.id === category.id).length;
      const completed = completedActions.filter((el) => el.category.id === category.id).length;

      return {
        ...category,
        allActionsPerCat,
        completed,
      };
    });

    return uniqBy(catsInfo, 'name');
  }, [allActions, completedActions]);

  const maxCategoryDuration = useMemo(() => {
    const sumDuration = map(groupedData, (group) => {
      const filteredGroup = filter(group, (el) => {
        if (tabIndex === 0) {
          return true; // consider all completed elements
        }
        if (tabIndex === 1) {
          return el.dateOfStarring !== null; // consider completed elements with dateOfStarring !== null
        }
        return false; // exclude all other elements
      });

      return reduce(
        filteredGroup,
        (acc, obj) => {
          return acc + convertDurationToMinutes(obj.duration);
        },
        0,
      );
    });

    if (!isEmpty(sumDuration)) {
      return Math.max(...sumDuration);
    }

    return 0;
  }, [groupedData, tabIndex]);

  const groupedActionsByCategory = useMemo(() => {
    const mappedData = map(groupedData, (group) => {
      const completed = filter(group, (el) => {
        if (tabIndex === 0) {
          // return el?.progressStatus === COMPLETE; // consider all completed elements
          return true;
        }

        if (tabIndex === 1) {
          return el.dateOfStarring !== null; // consider completed elements with dateOfStarring !== null
        }

        return false; // invalid tabIndex value
      });

      const [token, tone] = (group[0]?.category?.color ?? 'gray.300').split('.');

      const categoryAllActionsDuration = group?.reduce((acc, obj) => {
        if (tabIndex === 0) {
          return acc + convertDurationToMinutes(obj.duration); // consider all completed elements
        } else if (tabIndex === 1 && obj.dateOfStarring !== null) {
          return acc + convertDurationToMinutes(obj.duration); // consider completed elements with dateOfStarring !== null
        }

        return acc; // exclude non-completed elements
      }, 0);

      const categoryAllCompletedActionsDuration = group?.reduce((acc, obj) => {
        if (obj?.progressStatus === COMPLETE) {
          if (tabIndex === 0) {
            return acc + convertDurationToMinutes(obj.duration); // consider all completed elements
          } else if (tabIndex === 1 && obj.dateOfStarring !== null) {
            return acc + convertDurationToMinutes(obj.duration); // consider completed elements with dateOfStarring !== null
          }
          return acc; // exclude other completed elements
        }
        return acc; // exclude non-completed elements
      }, 0);

      return {
        value: completed.length / group?.length,
        backgroundColor: theme.colors?.[token]?.[tone],
        allActions: group?.length,
        categoryAllActionsDuration,
        categoryAllCompletedActionsDuration,
        category: group[0]?.category,
      };
    });

    const groupedActions = [...(filter(mappedData, (el) => el.allActions >= 1 && el.value > 0) ?? [])];

    return orderBy(groupedActions, 'categoryOrder', 'asc');
  }, [groupedData, tabIndex]);

  const hasAtLeastOneActionCompleted = useMemo(
    () => some(groupedActionsByCategory, (el) => el?.categoryAllCompletedActionsDuration !== 0),
    [groupedActionsByCategory],
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (!weeklyPlanId) {
        return;
      }

      if (previousReflectionValue === data?.reflection) {
        return;
      }

      await updateWeeklyPlanReflections.mutateAsync(
        {
          id: weeklyPlanId,
          reflection: data?.reflection,
        },
        {
          onSuccess: () => {
            toast({
              title: 'Reflection updated',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });

            trackReflectionEntered();
          },
          onError: (error) => {
            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [previousReflectionValue, toast, updateWeeklyPlanReflections, weeklyPlanId],
  );

  const completedGroupedByCategoryActionsInBlocks = useMemo(() => {
    const groupedItems = groupBy(completedActionsInBlocks, (item) => item.category.name);
    const sortedGroups = orderBy(Object.values(groupedItems), ([category]) => category, 'asc');
    const flattenGroup = flattenDeep(sortedGroups);

    return flattenGroup;
  }, [completedActionsInBlocks]);

  const getSortedActionsByCategoryId = useCallback(
    (categoryId: string) => {
      if (!completedGroupedByCategoryActionsInBlocks) {
        return [];
      }

      const categoryActions = getActionsByCategoryId(completedGroupedByCategoryActionsInBlocks, categoryId);

      let response = isDailyPlanPage
        ? getDailyActions(categoryActions, selectedDate)
        : getWeeklyActions(categoryActions, weeklyPlanId ?? '');

      response = response.map((action) => ({
        ...action,
      }));

      return orderBy(response, 'categoryOrder', 'asc');
    },
    [completedGroupedByCategoryActionsInBlocks, selectedDate, weeklyPlanId, isDailyPlanPage],
  );

  const categories = useMemo(() => {
    if (!categoriesList?.category) {
      return [];
    }

    const aggregatedCategories = categoriesList.category.map((category) => ({
      ...category,
      completedActions: getCompletedActions(getSortedActionsByCategoryId(category.id), 'modifiedAt'),
    }));

    // Filter categories that has at least 1 action completed
    return aggregatedCategories.filter((c) => c.completedActions.length !== 0);
  }, [categoriesList?.category, getSortedActionsByCategoryId]);

  useEffect(() => {
    if (weeklyPlanId) setValue('reflection', weeklyPlanReflections?.trim());
  }, [setValue, weeklyPlanId, weeklyPlanReflections]);

  useEffect(() => {
    return () => {
      queryClient.resetQueries({ queryKey: [actionsKeys.actionsAndBlock, selectedDate], exact: true });
      queryClient.resetQueries({ queryKey: keys.weeklyPlan.all._ctx.from(startDate).queryKey, exact: true });
    };
  }, [date, queryClient, selectedDate, startDate]);

  if (completedActionsAndBlockLoading) {
    return (
      <DashboardLayout pageTitle="Celebrate">
        <Flex alignItems="center" justifyContent="center" width="full" paddingY={rem(80)}>
          <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
        </Flex>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout pageTitle="Celebrate" paddingInline={0}>
      <Hero background={HeroCelebrate} marginTop={rem(-15)} animated />

      {(!isEmpty(completedActions) || !isEmpty(completedBlocks)) && !completedActionsAndBlockLoading && (
        <ConfettiCelebration nConfetti={4000} />
      )}

      <motion.div
        className="row"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageNavigationEffect}
        transition={pageTransition}
      >
        <Box padding={rem(16)} paddingBottom="2">
          <Grid
            ref={chartRef}
            gridGap={rem(32)}
            gridAutoRows="1fr"
            gridTemplateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          >
            {/* First column */}
            <Flex
              flexDirection="column"
              gridGap={rem(32)}
              width={{ base: fullWindowWidth - 32, xl: (fullWindowWidth - 80) / 2 }}
              height="full"
            >
              {/*Actions*/}
              <Flex flexDirection="column">
                <Text as="h5" textStyle="h5SatBlack" marginBottom={rem(20)}>
                  {`Actions completed ${isDailyPlanPage ? 'this day' : 'this week'}`}
                </Text>

                {!isEmpty(completedGroupedByCategoryActionsInBlocks) && !completedActionsAndBlockLoading ? (
                  <>
                    {Children.toArray(
                      categories.map((category) => (
                        <Box marginBottom={rem(16)}>
                          <CaptureList
                            key={category.id}
                            className={`${category.id}-completed-actions`}
                            category={category}
                            totalDurationOfIncompleteActions=""
                            totalDurationOfPreferredIncompleteActions=""
                            showDurationTimers={false}
                          >
                            <List width="full">
                              {category.completedActions.map((action, index) => (
                                <ActionListItem
                                  key={action.id}
                                  item={action}
                                  index={index}
                                  onDeleteAction={() => null}
                                  onClickCreateBlock={() => null}
                                  readOnly
                                  progressStatusButtonDisabled
                                />
                              ))}
                            </List>
                          </CaptureList>
                        </Box>
                      )),
                    )}
                  </>
                ) : (
                  <Flex alignItems="center" justifyContent="center" width="full" height="full">
                    <Text color="gray.400" fontSize={rem(14)}>
                      {`No Actions Completed ${isDailyPlanPage ? 'on This Day' : 'This Week'}`}
                    </Text>
                  </Flex>
                )}
              </Flex>

              {/* Blocks */}
              <Flex flexDirection="column">
                <Text as="h5" textStyle="h5SatBlack" marginBottom={rem(20)}>
                  {`RPM Blocks completed ${isDailyPlanPage ? 'this day' : 'this week'}`}
                </Text>

                {!isEmpty(completedBlocks) && !completedActionsAndBlockLoading ? (
                  <VStack overflow="auto" width="full">
                    {Children.toArray(completedBlocks?.map((block) => <CompletedBlockStatic {...block} />))}
                  </VStack>
                ) : (
                  <Flex alignItems="center" justifyContent="center" width="full" height="full">
                    <Text color="gray.400" fontSize={rem(14)}>
                      {`No RPM Blocks Completed ${isDailyPlanPage ? 'on This Day' : 'This Week'}`}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>

            {/* Second column */}
            <Flex
              flexDirection="column"
              gridGap={rem(32)}
              width={{ base: fullWindowWidth - 32, xl: (fullWindowWidth - 80) / 2 }}
              height="full"
            >
              {/*Reflections*/}
              <VStack alignItems="start" gap={rem(20)} width="full">
                <Text as="h5" textStyle="h5SatBlack">
                  {`Reflections on my ${isDailyPlanPage ? 'day' : 'week'}`}
                </Text>

                <Controller
                  name="reflection"
                  control={control}
                  render={({ field: { ref, value, onChange } }) => (
                    <Editable
                      ref={ref}
                      textStyle="small"
                      overflow="hidden"
                      width="full"
                      borderRadius={4}
                      defaultValue={value}
                      onChange={onChange}
                      onSubmit={() => handleSubmit(onSubmit)()}
                      placeholder={`${!isDailyPlanPage ? 'This week' : 'Today'}, I'm proud that I finished...`}
                      selectAllOnFocus={false}
                      submitOnBlur
                      value={value}
                    >
                      <EditablePreview
                        width="full"
                        height="full"
                        padding={rem(12)}
                        color={value ? 'text-primary' : 'gray.400'}
                        fontStyle={value ? 'normal' : 'italic'}
                        border={`${rem(1)} solid`}
                        borderColor="gray.900"
                      />
                      <EditableTextarea
                        as={TextareaAutosize}
                        height="full"
                        padding={rem(12)}
                        border={`${rem(1)} solid`}
                        borderColor="gray.900"
                        _focusVisible={{
                          border: `${rem(1)} solid`,
                          borderColor: 'cyan.400',
                          borderRadius: 4,
                        }}
                        resize="none"
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                        }
                      />
                    </Editable>
                  )}
                />
              </VStack>

              <Flex flexDirection="column" width="full">
                <HStack justifyContent="space-between" width="full">
                  <Text as="h5" textStyle="h5SatBlack">
                    {`${tabIndex === 0 ? 'Total' : 'Starred'} ${!isDailyPlanPage ? 'Weekly' : 'Daily'} Work by Category`}
                  </Text>

                  <HStack gap={rem(5)}>
                    {!isEmpty(groupedActionsByCategory) && (
                      <HStack marginRight={3}>
                        <Box
                          width={rem(10)}
                          height={rem(10)}
                          borderRadius={99}
                          backgroundColor="background-secondary"
                        />
                        <Text textStyle="small">Planned</Text>
                      </HStack>
                    )}

                    {!isEmpty(categoriesInfo) &&
                      !completedActionsAndBlockLoading &&
                      !isEmpty(groupedActionsByCategory) &&
                      hasAtLeastOneActionCompleted && (
                        <HStack marginRight={1}>
                          {Children.toArray(
                            groupedActionsByCategory?.map((el, index) => (
                              <Box
                                zIndex={index > 0 ? 1 : 'auto'}
                                display={el?.categoryAllCompletedActionsDuration === 0 ? 'none' : 'block'}
                                width={rem(10)}
                                height={rem(10)}
                                marginLeft={index > 0 ? '-14px !important' : '0'}
                                borderRadius={99}
                                backgroundColor={el?.category?.color}
                              />
                            )),
                          )}

                          <Text textStyle="small">Spent</Text>
                        </HStack>
                      )}

                    <DynamicSegmentedButton
                      options={chartFilters}
                      onChange={setTabIndex}
                      startIndex={0}
                      layoutId="Celebrate"
                    />
                  </HStack>
                </HStack>

                {!completedActionsAndBlockLoading && (
                  <Flex
                    alignSelf="center"
                    width={{
                      base: 'full',
                      xl: `calc(${fullWindowWidth / 2}px - 1.88rem)`,
                    }}
                    height="full"
                    paddingTop={rem(32)}
                  >
                    <CelebrateChart
                      data={groupedActionsByCategory}
                      width={fullWindowWidth}
                      maxCategoryDuration={maxCategoryDuration}
                    />
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Grid>
        </Box>
      </motion.div>
    </DashboardLayout>
  );
}
export default CelebratePage;
