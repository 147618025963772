import { DiscussionPoint } from '@/types/discussionPoints';
import {
  removeCurrentFromDiscussionPointsList,
  updateOrderOnIncompleteDiscussionPoint,
} from '@/utils/discussion-point';
import { getDiscussionPointsById } from '@/utils/people';
import { ReactNode, createContext, useCallback, useContext } from 'react';

type EqType = {
  _eq: string;
};

type WhereType = {
  id: EqType;
};

type SetWhereType = {
  _set: Partial<DiscussionPoint>;
  where: WhereType;
};

type DiscussionPointsListContextType = {
  items: DiscussionPoint[];
  onCompleteDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
  onIncompleteDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
  onToggleProgressDiscussionPoint?: (discussionPointId: string) => SetWhereType[];
};

const DiscussionPointsListContext = createContext<DiscussionPointsListContextType | null>(null);

interface DiscussionPointListProviderProps extends Pick<DiscussionPointsListContextType, 'items'> {
  children: ReactNode;
}

const DiscussionPointListProvider = ({ children, items }: DiscussionPointListProviderProps) => {
  const handleNewItemsOrder = (items: DiscussionPoint[]): SetWhereType[] => {
    return items.map((item, index) => ({
      _set: {
        order: index + 1,
      },
      where: {
        id: {
          _eq: item.id,
        },
      },
    }));
  };

  const makeIncompleteDiscussionPointPayload = (
    items: DiscussionPoint[],
    discussionPointId: string,
  ): SetWhereType[] => {
    return items.map((item, index) => {
      if (item.id === discussionPointId) {
        return {
          _set: {
            order: index + 1,
            isCompleted: false,
          },
          where: {
            id: {
              _eq: item.id,
            },
          },
        };
      }

      return {
        _set: {
          order: index + 1,
        },
        where: {
          id: {
            _eq: item.id,
          },
        },
      };
    });
  };

  const onCompleteDiscussionPoint = useCallback(
    (discussionPointId: string) => {
      const actions = removeCurrentFromDiscussionPointsList(items, discussionPointId);
      const newItems = handleNewItemsOrder(actions);

      return [
        ...newItems,
        {
          _set: {
            order: actions.length + 1,
            isCompleted: true,
          },
          where: {
            id: {
              _eq: discussionPointId,
            },
          },
        },
      ];
    },
    [items],
  );

  const onIncompleteDiscussionPoint = useCallback(
    (discussionPointId: string) => {
      const discussionPoint = getDiscussionPointsById(items, discussionPointId);

      if (!discussionPoint) {
        return [];
      }

      const discussionPoints = updateOrderOnIncompleteDiscussionPoint(items, {
        ...discussionPoint,
        isCompleted: false,
      });

      return makeIncompleteDiscussionPointPayload(discussionPoints, discussionPointId);
    },
    [items],
  );

  /* Temp function - Created only to remove the current behavior of pushing to the bottom of the list */
  const onToggleProgressDiscussionPoint = useCallback(
    (discussionPointId: string): SetWhereType[] => {
      const discussionPoint = getDiscussionPointsById(items, discussionPointId);

      if (!discussionPoint) {
        return [];
      }

      return items.map((item) => {
        if (item.id === discussionPointId) {
          return {
            _set: {
              isCompleted: !item.isCompleted,
            },
            where: {
              id: {
                _eq: item.id,
              },
            },
          };
        }

        return {
          _set: {},
          where: {
            id: {
              _eq: item.id,
            },
          },
        };
      });
    },
    [items],
  );

  return (
    <DiscussionPointsListContext.Provider
      value={{ items, onCompleteDiscussionPoint, onIncompleteDiscussionPoint, onToggleProgressDiscussionPoint }}
    >
      {children}
    </DiscussionPointsListContext.Provider>
  );
};

const useDiscussionPointList = () => {
  const context = useContext(DiscussionPointsListContext);

  if (!context) {
    return {
      items: [],
      onCompleteDiscussionPoint: undefined,
      onIncompleteDiscussionPoint: undefined,
      onToggleProgressDiscussionPoint: undefined,
    };
  }

  return context;
};

export { DiscussionPointListProvider, DiscussionPointsListContext, useDiscussionPointList };
