import AddEmailToPersonModal from '@/components/AddEmailToPersonModal';
import CreateLeverageRequestModal, { LeverageRequestPromise } from '@/components/CreateLeverageRequestModal';
import IconStarred from '@/components/IconStarred';
import InputDuration from '@/components/InputDuration';
import ManageActionRelations from '@/components/ManageActionRelations';
import ProjectsMenu from '@/components/ProjectsMenu';
import RPMDateTimePickerWithPlaceholder from '@/components/RPMDateTimePicker/RPMDateTimePickerWithPlaceholder';
import Textarea from '@/components/Textarea';
import { ActionRelation, INCOMPLETE } from '@/constants/action';
import { ActionControleSlideYInOutVariants } from '@/constants/animations';
import { DAY, WEEK } from '@/constants/calendar';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useCreateAction, useCreatePromises } from '@/services/action/hooks';
import { useCreateEvent } from '@/services/events/hooks';
import { usePeople } from '@/services/people/hooks';
import { getWeeklyPlanId, useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconPlus } from '@/theme/icons';
import { DayWeek } from '@/types/calendar';
import { Category } from '@/types/category';
import { ProjectType } from '@/types/project';
import { addActionToCache } from '@/utils/action';
import { formatDateToString, localDateToUTC } from '@/utils/calendar';
import { getColorFromToken } from '@/utils/color';
import mergeRefs from '@/utils/mergeRefs';
import rem from '@/utils/rem';
import { trackActionStarred } from '@/utils/tracking';
import { Box, Button, Checkbox, Grid, GridItem, HStack, Text, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimationControls, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const QuickActionCreatorFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Action name requires at least 1 character' })
    .max(100, { message: 'Action name must be 100 characters or less' }),
  time: z
    .string()
    .trim()
    .refine((value) => !!value, {
      message: 'Set a minimum duration in the format hh:mm',
    })
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          return false;
        }
        return true;
      },
      {
        message: 'Invalid time format',
      },
    )
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);

        if (hours === 0 && minutes === 0) {
          return false;
        }
        return true;
      },
      {
        message: 'The minimum duration is 5 minutes',
      },
    ),
  notes: z.string().optional(),
  createLeverageRequest: z.boolean().optional(),
});

type FormData = z.infer<typeof QuickActionCreatorFormSchema>;

type Props = {
  selectedCategoryId: string | undefined;
  category: Category | undefined;
  actionCreatorOptionsAnimation: AnimationControls;
};

function QuickActionCreator({ category, selectedCategoryId, actionCreatorOptionsAnimation }: Props) {
  const toast = useToast();
  const firstRowRef = useRef<HTMLDivElement>(null);
  const textAreaValueRef = useRef<HTMLTextAreaElement>(null);
  const [width, setWidth] = useState(0);
  const { selectedDate } = useCalendarMonthlyStore();
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(selectedDate);
  const [isStarred, setIsStarred] = useState<Date | null>(null);
  const [relation, setRelation] = useState<ActionRelation>(ActionRelation.None);
  const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);
  const [leverageRequestPromise, setLeverageRequestPromise] = useState<LeverageRequestPromise>();
  const [tabIndex, setTabIndex] = useState<typeof DAY | typeof WEEK>(WEEK);
  const [dateRange, setDateRange] = useState(tabIndex === WEEK);
  const isDarkMode = useIsDarkMode();
  const color = getColorFromToken(category?.color, undefined, undefined);

  const { data: weeklyPlanId } = useWeeklyPlanId(scheduledDate ?? new Date());

  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker' });

  const {
    isOpen: isCreateLeverageModalOpen,
    onOpen: onCreateLeverageModalOpen,
    onClose: onCreateLeverageModalClose,
  } = useDisclosure();

  const { isOpen: isAddEmailModalOpen, onOpen: onAddEmailModalOpen, onClose: onAddEmailModalClose } = useDisclosure();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    resetField,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      notes: '',
      time: '00:05',
      createLeverageRequest: false,
    },
    resolver: zodResolver(QuickActionCreatorFormSchema),
    mode: 'onChange',
  });

  const createLeverageRequestWatcher = watch('createLeverageRequest');

  const createAction = useCreateAction();
  const createPromises = useCreatePromises();
  const createEvent = useCreateEvent();
  const { data: people } = usePeople();

  // we are only supporting one person at this time but it will most likely change in the future
  // we previously supported selecting more than one person (before the create leverage request feature)
  // so we will keep that work in-tact as we will most likely need it again soon
  const selectedPerson = useMemo(() => {
    if (people?.person && selectedPeople.length) {
      return people.person.find((person) => person.id === selectedPeople[0]);
    }
  }, [people?.person, selectedPeople]);

  const onClickUnplan = useCallback(() => {
    setScheduledDate(null);
    onDatePickerClose();
  }, [onDatePickerClose]);

  const onChangeTabs = useCallback((index: string | number) => {
    if ((index as DayWeek) === WEEK) {
      setScheduledTime(null);
    }

    setTabIndex(index as DayWeek);
    setDateRange((index as DayWeek) === WEEK);
  }, []);

  const handleScheduledTime = useCallback(
    (date: Date | null) => {
      if (!date) {
        setScheduledTime(null);
        return;
      }

      if (scheduledDate) {
        scheduledDate.setHours(date.getHours());
        scheduledDate.setMinutes(date.getMinutes());
      }

      setScheduledTime(scheduledDate);
    },
    [scheduledDate],
  );

  const handleDatePickerClose = useCallback(() => {
    onDatePickerClose();
  }, [onDatePickerClose]);

  const onStarringAction = () => {
    setIsStarred((prevState) => {
      return prevState ? null : new Date(Date.now());
    });
  };

  const handleResetForm = useCallback(() => {
    resetField('name');
    resetField('time');
    setScheduledDate(selectedDate);
    setIsStarred(null);
    setSelectedPeople([]);
    setRelation(ActionRelation.None);
    setSelectedProject(null);
    textAreaValueRef?.current?.focus();
  }, [resetField, selectedDate]);

  useEffect(() => {
    setScheduledDate(selectedDate);
  }, [selectedDate]);

  const onSubmit = useCallback(
    (form: FormData) => {
      let currentWeeklyPlan: string | undefined = undefined;

      if (scheduledDate) {
        currentWeeklyPlan = weeklyPlanId;
      }

      if (currentWeeklyPlan == null && scheduledDate) {
        const cachedWeeklyPlanId = getWeeklyPlanId(scheduledDate);

        if (cachedWeeklyPlanId) {
          currentWeeklyPlan = cachedWeeklyPlanId;
        }
      }

      // @DOC: Ticket RRI-2104. This way should fix the TZ problem with actions with date without time and actions with date and time for Brazil and Europe
      let newScheduledTZDate = scheduledDate && formatDateToString(scheduledDate);

      if (scheduledDate && scheduledTime) {
        newScheduledTZDate = localDateToUTC(scheduledDate).scheduledDate;
      }

      createAction.mutate(
        {
          blockId: undefined,
          categoryId: category?.id ?? '',
          dateOfStarring: isStarred,
          duration: form.time,
          id: uuidv4(),
          isLocked: false,
          notes: form.notes ?? '',
          progressStatus: INCOMPLETE,
          projectId: selectedProject?.id ?? null,
          scheduledDate: tabIndex === DAY ? newScheduledTZDate : null,
          scheduledTime: scheduledTime ? localDateToUTC(scheduledTime).scheduledTime : null,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          title: form.name,
          weeklyPlanId: scheduledDate ? currentWeeklyPlan : undefined,
          // ...(incompleteActionsLength !== undefined && { projectOrder: incompleteActionsLength + 1 }),
        },
        {
          onSuccess: async (data) => {
            const { id: actionId } = data.insertActionOne;

            if (scheduledDate) {
              const event = await createEvent.mutateAsync({
                id: uuidv4(),
                actionId,
              });

              if (event?.insertEventOne) {
                data.insertActionOne.event = {
                  id: event.insertEventOne.id,
                  typeName: 'Event',
                };
              }
            }

            if (relation !== ActionRelation.None && selectedPeople.length) {
              const objects = selectedPeople.map((person) => ({
                id: uuidv4(),
                actionId,
                personId: person,
                kind: relation,
              }));

              const { insertPromise } = await createPromises.mutateAsync({ objects });
              // we are only supporting one "promise" creation (leverage request) at a time right now
              setLeverageRequestPromise(insertPromise.returning[0]);

              data.insertActionOne.promises = insertPromise.returning;
            }

            if (isStarred) {
              trackActionStarred();
            }

            addActionToCache(data.insertActionOne);

            toast({ title: 'Action created successfully', status: 'success', duration: 3000, isClosable: true });

            if (createLeverageRequestWatcher) {
              onCreateLeverageModalOpen();
            } else {
              handleResetForm();
            }
          },
        },
      );
    },
    [
      scheduledDate,
      scheduledTime,
      createAction,
      category?.id,
      isStarred,
      selectedProject?.id,
      tabIndex,
      weeklyPlanId,
      relation,
      selectedPeople,
      toast,
      handleResetForm,
      createEvent,
      createPromises,
      createLeverageRequestWatcher,
      onCreateLeverageModalOpen,
    ],
  );

  const openEmailModalOrSubmit = useCallback(
    (form: FormData) => {
      if (createLeverageRequestWatcher && !selectedPerson?.email) {
        onAddEmailModalOpen();
      } else {
        onSubmit(form);
      }
    },
    [onAddEmailModalOpen, onSubmit, createLeverageRequestWatcher, selectedPerson?.email],
  );

  const handleUserEnterFormSubmit = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        handleSubmit(openEmailModalOrSubmit)();
      }
    },
    [handleSubmit, openEmailModalOrSubmit],
  );

  useEffect(() => {
    if (isValid && firstRowRef.current) {
      setWidth(firstRowRef.current.offsetWidth);
    }
  }, [isValid]);

  useEffect(() => {
    if (isValid || isDirty) {
      actionCreatorOptionsAnimation?.start('visible');
    } else {
      actionCreatorOptionsAnimation?.start('exit');
    }
  }, [isValid, isDirty, actionCreatorOptionsAnimation]);

  useEffect(() => {
    return () => {
      handleResetForm();
    };
    //!TODO: remove fix effect so we don't need to rely on eslint line below
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.id]);

  useEffect(() => {
    if (relation !== ActionRelation.Leverage) {
      setValue('createLeverageRequest', false);
    }
  }, [relation, setValue]);

  return (
    <VStack alignItems="start" gap={rem(24)} width="full">
      <HStack textStyle="h4SatBlack" display="inline-block" width="full" color="text-primary">
        <Text as="span" width="fit-content" marginRight={rem(8)} wordBreak="break-word">
          {category?.role ? 'Are you ready to be a' : 'Are you ready to'}
        </Text>

        <Text
          as="span"
          width="fit-content"
          color={isDarkMode ? category?.color : getColorFromToken(category?.color, undefined, '100')}
          wordBreak="break-word"
        >
          {category?.role ?? 'achieve your goals this week'}
        </Text>

        <Text as="span" width="fit-content" marginRight={rem(8)} wordBreak="break-word">
          ?
        </Text>
      </HStack>

      <form
        onSubmit={handleSubmit(openEmailModalOrSubmit)}
        style={{
          width: '100%',
        }}
      >
        <Box position="relative" zIndex={1}>
          <Controller
            name="name"
            control={control}
            render={({ field: { ref, value, onChange } }) => (
              <Textarea
                ref={mergeRefs(ref, textAreaValueRef)}
                autoComplete="off"
                placeholder="Capture an Action..."
                value={value}
                borderRadius={rem(5)}
                isDisabled={isSubmitting}
                onChange={onChange}
                error={errors?.name?.message}
                errorMessageProps={{ marginTop: rem(-6), marginBottom: rem(6) }}
                _placeholder={{ color: 'text-primary', opacity: 0.5, textStyle: 'small' }}
                fontSize={rem(12)}
                fontWeight={500}
                lineHeight="130%"
                letterSpacing="0"
                marginBottom={rem(12)}
                backgroundColor="background-primary"
                padding={`${rem(16)} ${rem(10)}`}
                width="full"
                resize="none"
                border="none !important"
                onKeyDownCapture={handleUserEnterFormSubmit}
                rows={2}
                sx={{
                  caretColor: color,
                }}
              />
            )}
          />
        </Box>

        <motion.div
          initial="hidden"
          animate={actionCreatorOptionsAnimation}
          variants={ActionControleSlideYInOutVariants}
          transition={{ duration: 0.2 }}
          style={{
            position: 'relative',
            height: isDirty ? 'auto' : 0,
            zIndex: isDirty ? 1 : -1,
          }}
        >
          <Grid
            gridGap={rem(8)}
            gridTemplateColumns={{
              base: 'repeat(1, 1fr)',
              '2xl': 'repeat(2, 1fr)',
            }}
            display={isDirty ? 'grid' : 'none'}
            width="full"
          >
            <GridItem>
              <VStack align="start">
                <HStack ref={firstRowRef}>
                  {/* Input Duration */}
                  <VStack align="start">
                    <Controller
                      name="time"
                      control={control}
                      render={({ field: { value, onChange } }) => <InputDuration value={value} onChange={onChange} />}
                    />
                  </VStack>

                  {/* Date and Time */}
                  <RPMDateTimePickerWithPlaceholder
                    isDatePickerOpen={isDatePickerOpen}
                    scheduledTime={scheduledTime}
                    tabIndex={tabIndex}
                    onDatePickerOpen={onDatePickerOpen}
                    onClickUnplan={onClickUnplan}
                    dateRange={dateRange}
                    onChangeTabs={onChangeTabs}
                    onSave={handleDatePickerClose}
                    onCancel={onDatePickerClose}
                    scheduledDate={scheduledDate}
                    updateScheduledTime={handleScheduledTime}
                    updateSelectedDate={setScheduledDate}
                    withTimePicker={tabIndex === DAY}
                  />

                  {/* Star button */}
                  <IconStarred
                    starred={Boolean(isStarred)}
                    onClick={onStarringAction}
                    _hover={{ backgroundColor: 'background-quaternary' }}
                    minWidth={rem(28)}
                    minHeight={rem(28)}
                    height={rem(28)}
                    width={rem(28)}
                  />

                  {/* Leverage/commit button */}
                  <ManageActionRelations
                    initialRelation={ActionRelation.None}
                    onRelationChange={setRelation}
                    onSelectPeople={setSelectedPeople}
                    shouldResetState={selectedPeople.length < 1}
                    selectedPeople={selectedPeople}
                  />
                </HStack>

                {errors.time && (
                  <Box as="span" marginTop={rem(-3)} color="red.500" fontSize="sm">
                    {errors.time.message}
                  </Box>
                )}

                {/* Project selector */}
                <ProjectsMenu
                  width={width}
                  showCategoryIcon={false}
                  categoryId={selectedCategoryId}
                  project={selectedProject}
                  setSelectedProject={setSelectedProject}
                />

                <Controller
                  name="createLeverageRequest"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      sx={{
                        '.chakra-checkbox__control[data-disabled] ': {
                          background: isDarkMode ? 'whiteAlpha.100' : 'gray.200 !important',
                        },
                      }}
                      marginTop={rem(4)}
                      isChecked={value}
                      isDisabled={!(relation === ActionRelation.Leverage && selectedPeople.length)}
                      onChange={onChange}
                      size="sm"
                    >
                      <Text as="span" textStyle="small">
                        Create Leverage Request
                      </Text>
                    </Checkbox>
                  )}
                />
              </VStack>
            </GridItem>

            <GridItem>
              <HStack justifyContent="end">
                <Button
                  height={rem(28)}
                  borderRadius={rem(8)}
                  isDisabled={isDatePickerOpen}
                  onClick={handleResetForm}
                  variant="tertiary"
                >
                  <Text textStyle="smallBlack">Cancel</Text>
                </Button>
                <Button
                  height={rem(28)}
                  borderRadius={rem(4)}
                  isDisabled={
                    !isValid || createAction.isLoading || createPromises.isLoading || !weeklyPlanId || isDatePickerOpen
                  }
                  isLoading={createAction.isLoading}
                  leftIcon={<Box as={IconPlus} width={rem(14)} height={rem(14)} />}
                  paddingX={rem(8)}
                  type="submit"
                  variant="primary"
                >
                  <Text textStyle="smallBlack">Add Action</Text>
                </Button>
              </HStack>
            </GridItem>
          </Grid>
        </motion.div>
      </form>

      {selectedPerson && (
        <AddEmailToPersonModal
          isOpen={isAddEmailModalOpen}
          onClose={onAddEmailModalClose}
          person={selectedPerson}
          onSuccess={() => {
            handleSubmit(openEmailModalOrSubmit);
            onAddEmailModalClose();
          }}
        />
      )}
      {selectedPerson && leverageRequestPromise && (
        <CreateLeverageRequestModal
          isOpen={isCreateLeverageModalOpen}
          onClose={() => {
            onCreateLeverageModalClose();
            handleResetForm();
          }}
          title={getValues('name')}
          person={selectedPerson}
          dueDate={scheduledDate}
          tabIndex={tabIndex}
          leverageRequestPromise={leverageRequestPromise}
        />
      )}
    </VStack>
  );
}

export default QuickActionCreator;
