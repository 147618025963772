import ManageActionMenu from '@/components/ManageActionRelations/ManageActionMenu';
import SelectRelationMode from '@/components/ManageActionRelations/SelectRelationMode';
import ManagePersonModal from '@/components/ManagePersonModal';
import PersonAvatar from '@/components/PersonAvatar';
import { ActionRelation } from '@/constants/action';
import { LeverageRequestStatus } from '@/gql/actions/types';
import { usePeople } from '@/services/people/hooks';
import { IconCommitPersonOutline, IconLeveragePersonOutline, IconPerson } from '@/theme/icons';
import { Person } from '@/types/people';
import rem from '@/utils/rem';
import { Box, Flex, IconButton, Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';

const RelationIcon = {
  [ActionRelation.None]: IconPerson,
  [ActionRelation.Leverage]: IconLeveragePersonOutline,
  [ActionRelation.Commit]: IconCommitPersonOutline,
};

type Props = {
  initialRelation: ActionRelation;
  onSelectPeople: (people: string[]) => void;
  onPersonAvatarClick?: () => void;
  onRelationChange: (relation: ActionRelation) => void;
  disableSelection?: boolean;
  selectedPeople: string[];
  shouldResetState?: boolean;
  buttonBackgroundColor?: string;
  selectedBackgroundColor?: string;
  leverageStatus?: LeverageRequestStatus;
  variant?: 'default' | 'bordered';
};

function ManageActionRelations({
  initialRelation,
  onSelectPeople,
  onPersonAvatarClick,
  onRelationChange,
  disableSelection = false,
  selectedPeople,
  shouldResetState = false,
  buttonBackgroundColor = 'background-primary', // selectedBackgroundColor = 'background-quaternary',
  leverageStatus,
  variant,
}: Props) {
  const { data } = usePeople();

  const [mode, setMode] = useState<ActionRelation>(initialRelation);
  const [modalMode, setModalMode] = useState<ActionRelation>(initialRelation);

  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure({ id: 'menu' });
  const {
    isOpen: isCreatePersonOpen,
    onOpen: onCreatePersonOpen,
    onClose: onCreatePersonClose,
  } = useDisclosure({ id: 'createPerson' });

  const buttonIcon = selectedPeople.length ? RelationIcon[mode] : IconPerson;

  const firstSelectedPerson = useMemo(() => {
    if (data?.person && selectedPeople.length) {
      return data.person.find((person) => person.id === selectedPeople[0]);
    }
  }, [data?.person, selectedPeople]);

  const selectPerson = useCallback(
    (id: string) => {
      const isSelected = selectedPeople.includes(id);

      // the component was built to support leveraging multiple people
      // but at the moment you can only leverage one person

      // if (mode === ActionRelation.Leverage) {
      //   setSelectedPeople((prev) => (isSelected ? prev.filter((person) => person !== id) : [...prev, id]));
      // } else {
      //   setSelectedPeople(() => (isSelected ? [] : [id]));
      // }

      onSelectPeople(isSelected ? [] : [id]);

      onMenuClose();
    },
    [onMenuClose, selectedPeople, onSelectPeople],
  );

  const handleCreatePerson = useCallback(
    (person: Person) => {
      selectPerson(person.id);
      setMode(modalMode);
    },
    [modalMode, selectPerson],
  );

  const switchMode = (mode: ActionRelation) => {
    setMode(mode);
  };

  const handleMenuOpen = () => {
    if (!disableSelection) {
      onMenuOpen();
    }
  };

  const handleReset = () => {
    setMode(ActionRelation.None);
    onSelectPeople([]);
  };

  const handleCreatePersonOpen = () => {
    setModalMode(mode);
    onCreatePersonOpen();
    onMenuClose();
  };

  useEffect(() => {
    if (!isMenuOpen && !selectedPeople.length) {
      setMode(ActionRelation.None);
    }
  }, [isMenuOpen, selectedPeople.length]);

  useEffect(() => {
    if (shouldResetState) {
      onSelectPeople([]);
      setMode(ActionRelation.None);
    }
  }, [shouldResetState, onSelectPeople]);

  useEffect(() => {
    if (!selectedPeople.length) {
      setMode(ActionRelation.None);
    }

    onSelectPeople(selectedPeople);
  }, [onSelectPeople, selectedPeople]);

  useEffect(() => {
    onRelationChange(mode);
  }, [mode, onRelationChange]);

  return (
    <Box>
      <Menu isLazy isOpen={isMenuOpen} onClose={onMenuClose} onOpen={handleMenuOpen}>
        <Flex gap={rem(8)} borderRadius={rem(8)} backgroundColor="background-tertiary">
          <MenuButton
            as={IconButton}
            width={rem(28)}
            minWidth={rem(28)}
            height={rem(28)}
            minHeight={rem(28)}
            border={variant === 'bordered' ? `${rem(1)} solid var(--chakra-colors-stroke-primary)` : undefined}
            borderRadius={rem(4)}
            _hover={{
              backgroundColor: 'background-quaternary',
            }}
            aria-label="Person actions"
            backgroundColor={isMenuOpen ? 'background-quaternary' : buttonBackgroundColor}
            disabled={disableSelection}
            icon={<Box as={buttonIcon} margin="0 auto" />}
            variant="unstyled"
          />
          {!!selectedPeople.length && (
            <PersonAvatar
              name={firstSelectedPerson?.name}
              backgroundColor={buttonBackgroundColor}
              width={rem(28)}
              height={rem(28)}
              leverageStatus={leverageStatus}
              onClick={onPersonAvatarClick}
              border={variant === 'bordered' ? `${rem(1)} solid var(--chakra-colors-stroke-primary)` : undefined}
              textStyle="smallBlack"
            />
          )}
        </Flex>

        <MenuList
          position="relative"
          zIndex={1}
          minWidth={rem(300)}
          borderRadius={rem(10)}
          // backgroundColor="gray.900"
          backgroundColor="transparent"
          filter="drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12))"
          marginInline="0"
          paddingY="0"
        >
          {mode === ActionRelation.None ? (
            <SelectRelationMode onChangeMode={switchMode} />
          ) : (
            <ManageActionMenu
              onSelectPerson={selectPerson}
              people={data?.person || []}
              selectedPeople={selectedPeople}
              mode={mode}
              onCreateModalOpen={handleCreatePersonOpen}
              onReset={handleReset}
            />
          )}
        </MenuList>
      </Menu>
      <ManagePersonModal isOpen={isCreatePersonOpen} onClose={onCreatePersonClose} onCreate={handleCreatePerson} />
    </Box>
  );
}

export default ManageActionRelations;
