import GradientCard from '@/components//BlockItem/GradientCard';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import { CATEGORIES_ICONS } from '@/constants/category';
import { IconChevronDown, IconHourglass, IconStarFull } from '@/theme/icons';
import { Block } from '@/types/block';
import { calculateTotalDuration } from '@/utils/action';
import { getColorFromToken } from '@/utils/color';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { fixUncategorizedName, humanDuration } from '@/utils/index';
import rem from '@/utils/rem';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  List,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { filter } from 'lodash';
import { useMemo } from 'react';

type Props = Block;

const CompletedBlockStatic = (block: Props) => {
  const completedActionsInBlock = useMemo(
    () => filter(block?.actions, (el) => el.progressStatus === 'complete'),
    [block?.actions],
  );

  const totalDuration = humanDuration(calculateTotalDuration(completedActionsInBlock) * 60);
  const starredActionsTotalDuration = getTotalDurationOfStarredActions(completedActionsInBlock);
  const categoryColor = block.category.color ?? 'gray.300';

  return (
    <Box
      width="full"
      marginBottom={rem(12)}
      padding={rem(16)}
      bgGradient={`linear-gradient(270deg, ${getColorFromToken(categoryColor, '0.10')} 0%, var(--chakra-colors-background-primary) 66.97%)`}
      borderWidth={1}
      borderStyle="solid"
      borderColor={getColorFromToken(categoryColor, block.isCompleted ? '0.5' : '1')}
      borderRadius="base"
    >
      <Accordion allowToggle defaultIndex={[0]} reduceMotion>
        <AccordionItem>
          <Box>
            <Flex justifyContent="space-between" width="full">
              <Flex alignItems="center" flex="1">
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width={rem(14)}
                  height={rem(14)}
                  marginRight={rem(4)}
                  borderRadius="full"
                  aspectRatio={1}
                >
                  <Icon
                    as={CATEGORIES_ICONS?.[block?.category?.icon ?? 'uncategorized']}
                    width={rem(14)}
                    height={rem(14)}
                    color={categoryColor}
                  />
                </Flex>
                <Tooltip label={fixUncategorizedName(block.category?.name)} placement="top">
                  <Text
                    textStyle="xsmall"
                    overflow="hidden"
                    paddingRight={rem(6)}
                    color="text-primary"
                    textTransform="uppercase"
                    noOfLines={1}
                  >
                    {fixUncategorizedName(block.category?.name)}
                  </Text>
                </Tooltip>
              </Flex>
              <HStack>
                <Tooltip label="Time remaining to complete all of your starred actions" placement="top">
                  <Flex alignItems="center" minWidth={rem(30)}>
                    <Icon as={IconStarFull} width={rem(12)} height={rem(12)} color="gray.300" />
                    <Text textStyle="xsmall" marginLeft={rem(4)} color="text-primary">
                      {starredActionsTotalDuration || '0h'}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Time remaining to complete all of your actions" placement="top">
                  <Flex alignItems="center">
                    <Icon as={IconHourglass} width={rem(12)} height={rem(12)} color="gray.300" />
                    <Text textStyle="xsmall" marginLeft={rem(4)} color="text-primary">
                      {totalDuration || '0h'}
                    </Text>
                  </Flex>
                </Tooltip>
                <Flex alignItems="center" marginLeft={rem(8)}>
                  <AccordionButton display="flex" width={rem(14)} height={rem(14)} padding="0">
                    <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
                  </AccordionButton>
                </Flex>
              </HStack>
            </Flex>
            <GradientCard title="Result" body={block.result} categoryColor={categoryColor} />
          </Box>
          <AccordionPanel padding="0">
            <GradientCard title="Purpose" body={block.purpose} categoryColor={categoryColor} />
            <Accordion marginTop={rem(16)} allowToggle defaultIndex={[0]} reduceMotion>
              <AccordionItem>
                <AccordionButton gap={rem(4)} padding="0">
                  <Flex textStyle="xsmall" flex="1" textAlign="left">
                    <Text textStyle="xsmall" color="text-secondary" textTransform="uppercase">
                      {completedActionsInBlock.length} Completed Actions
                    </Text>
                  </Flex>
                  <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
                </AccordionButton>
                <AccordionPanel marginTop={rem(8)} padding={0} paddingBottom={4}>
                  <List width="full">
                    {completedActionsInBlock.map((action, index) => (
                      <ActionListItem
                        key={action.id}
                        item={{ ...action, block }}
                        index={index}
                        disabled
                        readOnly
                        progressStatusButtonDisabled
                      />
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default CompletedBlockStatic;
