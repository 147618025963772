import CompleteDeclineLeverageRequestModal from '@/components/CompleteDeclineLeverageRequestModal';
import { PublicLeverageRequest } from '@/gql/publicLeverageRequests/types';
import { usePublicLeverageRequests, useUpdatePublicLeverageRequest } from '@/services/publicLeverageRequests/hooks';
import { SIGN_IN_URL } from '@/services/user/hooks';
import { CMImageTen } from '@/theme/StaticImages';
import { IconChatBubble, IconClock, IconCommitPersonOutline, IconLogo } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import { getLeverageStatusIcon } from '@/utils/promises';
import rem from '@/utils/rem';
import {
  Box,
  Button,
  DarkMode,
  Divider,
  Flex,
  Grid,
  Icon,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  VisuallyHidden,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';

function RequestButtons({ personToken = '', request }: { personToken?: string; request: PublicLeverageRequest }) {
  const { mutate, isLoading } = useUpdatePublicLeverageRequest();

  const [showCompleteDeclineModal, setShowCompleteDeclineModal] = useState<'complete' | 'decline' | false>(false);
  return (
    <>
      <Button
        width={rem(115)}
        height={rem(24)}
        fontSize="xs"
        textTransform="uppercase"
        background="red.500"
        _hover={{ backgroundColor: 'red.400' }}
        onClick={() => setShowCompleteDeclineModal('decline')}
      >
        Decline
      </Button>
      {request.leverageRequestStatus === 'pending' && (
        <Button
          width={rem(115)}
          height={rem(24)}
          fontSize="xs"
          textTransform="uppercase"
          isLoading={isLoading}
          onClick={() =>
            mutate({
              token: personToken,
              promiseId: request.promiseId,
              leverageRequestStatus: 'accepted',
            })
          }
        >
          Accept
        </Button>
      )}
      {request.leverageRequestStatus === 'accepted' && (
        <Button
          width={rem(115)}
          height={rem(24)}
          fontSize="xs"
          textTransform="uppercase"
          background="green.400"
          _hover={{ backgroundColor: 'green.300' }}
          onClick={() => setShowCompleteDeclineModal('complete')}
        >
          Complete
        </Button>
      )}

      <CompleteDeclineLeverageRequestModal
        isOpen={!!showCompleteDeclineModal}
        onClose={() => setShowCompleteDeclineModal(false)}
        personToken={personToken}
        leverageRequest={request}
        variant={showCompleteDeclineModal || undefined}
      />
    </>
  );
}

function LeverageRequestsPage() {
  const { personToken } = useParams();
  // Token should always be present, if not we are happy letting the query crash
  const { data } = usePublicLeverageRequests(personToken ?? '');

  const sortedLeverageRequests = useMemo(
    () =>
      // BE returning order changes when a leverage request changes state, so to
      // keep the UI stable we apply a sort by date here
      data?.publicLeverageRequests.toSorted(
        (a, b) => new Date(a.leverageRequestedAt ?? 0).getTime() - new Date(b.leverageRequestedAt ?? 0).getTime(),
      ),
    [data],
  );

  return (
    // TODO: implement light mode for this page
    <DarkMode>
      <Helmet>
        <title>Your Actions | RPM</title>
      </Helmet>
      {/* TODO: remove the overflowY scroll when bug in daily planner is fixed (see PR #1046 for details */}
      <Box overflowY="scroll" paddingBottom={rem(32)} textColor="white" backgroundColor="blue.1000">
        {/* Header with RPM logo */}
        <Flex
          alignItems="center"
          justifyContent="start"
          gap={rem(8)}
          width="full"
          height={rem(50)}
          background="background-primary"
          paddingX={rem(16)}
          paddingY={rem(8)}
        >
          <Box as={IconLogo} width={rem(26)} height={rem(26)} />
          <Text fontSize={rem(21)} fontWeight={900} lineHeight="initial">
            RPM
          </Text>
        </Flex>

        {/* Opening text */}
        <Box position="relative" zIndex={0}>
          <Box
            position="absolute"
            zIndex={-1}
            width="full"
            height="50vw"
            minHeight={rem(300)}
            maxHeight={rem(800)}
            background={`linear-gradient(180deg, ${getColorFromToken('blue.1000', '0.50')} 18.43%, ${getColorFromToken(
              'blue.1000',
              '1',
            )} 83.38%), url(${CMImageTen}), lightgray 50%`}
            backgroundSize="cover"
            backgroundPosition="center center"
            backgroundRepeat="no-repeat"
            backgroundColor="blue.1000"
          />
          <Box width={`calc(100vw - (${rem(16)} * 2))`} maxWidth={rem(1000)} marginX="auto">
            <Text as="h1" textStyle="h2BarSB" paddingTop={rem(40)} paddingBottom={rem(15)} textTransform="uppercase">
              Welcome to RPM
            </Text>
            <Text
              textStyle="h3BarSB"
              paddingBottom={rem(10)}
              fontSize={{ base: rem(21), sm: rem(32) }}
              textTransform="uppercase"
            >
              Accelerate your results & maximize your fulfillment
            </Text>
            <Flex flexWrap={{ base: 'wrap', md: 'nowrap' }} gap={rem(35)}>
              <Text width={{ base: '100%', md: '50%' }} fontSize={{ base: rem(14), sm: rem(16) }} fontWeight={500}>
                Welcome to the Rapid Planning Method (RPM) by Tony Robbins! The RPM software is designed to help you
                focus on the things that really matter most to you and those you want to serve. When you focus on the
                Results (the target you’re after with clear specifics) and the Purpose (the fuel, energy, and drive that
                will get you there), you can put together a quick Map or Massive Action Plan to achieve your results
                faster than ever before.
              </Text>

              <Text width={{ base: '100%', md: '50%' }} fontSize={{ base: rem(14), sm: rem(16) }} fontWeight={500}>
                <span style={{ fontWeight: 900 }}>
                  This page is specifically created to assist you in tracking and updating the status of the action
                  items (requests) you have received from another user of the RPM software.
                </span>{' '}
                Stay organized, stay on track, and ensure that all your actions are completed efficiently and
                effectively with the help of RPM.
              </Text>
            </Flex>
            <Divider paddingTop={rem(35)} opacity={0.5} borderColor="gray.300" />
            {/* Actions list */}
            <Box as="section">
              <Text
                as="h2"
                textStyle="h5BarSB"
                paddingTop={rem(35)}
                paddingBottom={rem(8)}
                textTransform="uppercase"
                size="md"
              >
                Your actions
              </Text>

              <Text paddingBottom={rem(24)} fontSize={{ base: rem(14), sm: rem(16) }} fontWeight={500}>
                Remember to bookmark this page so you can easily return to review and update your status.
              </Text>

              {!sortedLeverageRequests && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  gap={rem(10)}
                  paddingTop={rem(25)}
                  paddingBottom={rem(125)}
                >
                  <Spinner size="lg" />
                </Flex>
              )}

              {sortedLeverageRequests?.length === 0 && (
                <Text textStyle="h5BarSB" paddingTop={rem(25)} paddingBottom={rem(100)} textAlign="center">
                  You completed all your actions, great!
                </Text>
              )}

              {!!sortedLeverageRequests?.length && (
                <UnorderedList flexDirection="column" gap={rem(16)} display="flex" width="full" margin={0}>
                  {sortedLeverageRequests.map((item) => (
                    <ListItem
                      key={item.promiseId}
                      sx={{ '--indentation': rem(35) }}
                      flexDirection="column"
                      display="flex"
                      width="full"
                      padding={rem(14)}
                      background="gray.950"
                      borderRadius={rem(12)}
                    >
                      <Flex flexDirection="column">
                        <Flex alignItems="start">
                          <Box width="var(--indentation)">
                            <Grid
                              width={rem(24)}
                              height={rem(24)}
                              background="background-primary"
                              borderRadius={rem(4)}
                              placeItems="center"
                            >
                              <VisuallyHidden>Status: {item.leverageRequestStatus}</VisuallyHidden>
                              <Icon
                                as={getLeverageStatusIcon(item.leverageRequestStatus ?? null)}
                                width={rem(14)}
                                height={rem(14)}
                                color="#38BDF8"
                                aria-hidden
                              />
                            </Grid>
                          </Box>

                          <Box paddingTop={rem(1)} paddingRight={{ sm: rem(16) }} fontSize={rem(14)}>
                            {item.actionTitle}
                          </Box>

                          <Flex gap={rem(16)} display={{ base: 'none', sm: 'flex' }} marginLeft="auto">
                            <RequestButtons personToken={personToken} request={item} />
                          </Flex>
                        </Flex>

                        <Flex
                          flexWrap="wrap"
                          rowGap={rem(8)}
                          columnGap={rem(16)}
                          paddingTop={rem(8)}
                          paddingLeft={'var(--indentation)'}
                          whiteSpace="nowrap"
                        >
                          <Flex
                            textStyle="xsmall"
                            alignItems="center"
                            gap={rem(8)}
                            fontWeight={900}
                            textTransform="uppercase"
                            background="background-primary"
                            borderRadius={rem(4)}
                            paddingX={rem(7)}
                            paddingY={rem(5)}
                          >
                            <Icon as={IconCommitPersonOutline} width={rem(14)} height={rem(14)} aria-hidden />
                            {item.senderName}
                          </Flex>

                          {item.leverageRequestDueDate && (
                            <Flex
                              textStyle="small"
                              alignItems="center"
                              gap={rem(8)}
                              background="background-primary"
                              borderRadius={rem(4)}
                              paddingX={rem(7)}
                              paddingY={rem(5)}
                            >
                              <Icon as={IconClock} width={rem(14)} height={rem(14)} aria-hidden />
                              Due Date: {format(parseISO(item.leverageRequestDueDate), "MMM d',' yyyy")}
                            </Flex>
                          )}
                        </Flex>
                      </Flex>

                      {item.leverageRequestText && (
                        <>
                          <Divider opacity={0.3} borderColor="gray.700" marginY={rem(16)} />
                          <Flex gap={rem(8)} paddingLeft={{ sm: 'var(--indentation)' }}>
                            <Icon as={IconChatBubble} width={rem(16)} height={rem(16)} color="gray.300" aria-hidden />
                            <Text textStyle="small">{item.leverageRequestText}</Text>
                          </Flex>
                        </>
                      )}

                      {/* Request buttons visible only on mobile */}
                      <Flex
                        justifyContent="center"
                        gap={rem(16)}
                        display={{ base: 'flex', sm: 'none' }}
                        paddingTop={rem(16)}
                      >
                        <RequestButtons personToken={personToken} request={item} />
                      </Flex>
                    </ListItem>
                  ))}
                </UnorderedList>
              )}
            </Box>
          </Box>

          <Box as="section" paddingTop={rem(24)} textAlign="center" paddingX={rem(16)}>
            <Text paddingBottom={rem(5)} fontSize={rem(12)} fontWeight={900} textTransform="uppercase">
              How would your life change if you focused on what truly matters?
            </Text>
            <Text
              as="h2"
              textStyle="h3BarSB"
              paddingBottom={rem(30)}
              lineHeight={1}
              textTransform="uppercase"
              size="md"
            >
              Start changing your life today
            </Text>
            <Button
              as={Link}
              minWidth={rem(274)}
              height="auto"
              padding={rem(14)}
              fontSize={rem(14)}
              textTransform="uppercase"
              to={SIGN_IN_URL}
            >
              Create Account
            </Button>
          </Box>

          {/* this will most likely be added again in the future with a copy update, so leaving in place for now */}
          {/* <Flex as="section" alignItems="center" paddingTop={rem(40)}>
            <Flex justifyContent={{ base: 'center', lg: 'end' }} width={{ base: 'full', lg: '40%' }}>
              <Box
                maxWidth={rem(520)}
                paddingTop={rem(50)}
                paddingBottom={rem(100)}
                fontSize={{ base: rem(16), sm: rem(18) }}
                textAlign={{ base: 'center', lg: 'left' }}
                paddingX={rem(16)}
              >
                <Text
                  as="h2"
                  textStyle="h3BarSB"
                  paddingBottom={rem(40)}
                  fontSize={{ base: rem(36), sm: rem(56) }}
                  lineHeight="0.9"
                  textTransform="uppercase"
                >
                  Why buy a Tony Robbins Magic Moment?
                </Text>
                <Text paddingBottom={rem(30)}>
                  History shows how rare baseball cards, old coins, and war memorabilia have a unique value in the
                  market. NFT’s are today’s collectables.
                </Text>
                <Text paddingBottom={rem(24)}>
                  Tony Robbins Magic Moments are pieces of unique, collectable content that are redeemable for access to
                  exclusive content, events, and once in a lifetime opportunities as part of the Tony Robbins community.
                </Text>
                <Button
                  minWidth={rem(180)}
                  height="auto"
                  padding={rem(14)}
                  fontSize={rem(14)}
                  textTransform="uppercase"
                >
                  Learn More
                </Button>
              </Box>
            </Flex>

            <Flex position="relative" display={{ base: 'none', lg: 'flex' }} overflow="hidden" width="60%">
              <Flex sx={{ '--glow-border-radius': rem(200) }} position="relative" shrink={0}>
                <Image
                  width="auto"
                  height={rem(764)}
                  borderRadius="var(--glow-border-radius)"
                  objectFit="cover"
                  objectPosition="30% center"
                  src={TonyImage}
                />
                <Box
                  position="absolute"
                  borderRadius="var(--glow-border-radius)"
                  boxShadow="inset 0 0 70px 70px var(--chakra-colors-background-primary)"
                  inset={0}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box
            sx={{ filter: 'blur(10vw)' }}
            position="absolute"
            zIndex={-1}
            bottom="0"
            left="0"
            display={{ base: 'none', lg: 'block' }}
            width="14vw"
            height="14vw"
            background="#13ADCF"
            borderTopRightRadius="100%"
          /> */}
        </Box>
      </Box>
    </DarkMode>
  );
}

export default LeverageRequestsPage;
