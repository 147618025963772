import ScheduledWeeklyTimeSlot from '@/components/DragAndDropCalendar/TimeSlot';
import { DayToolbar } from '@/components/DragAndDropCalendar/Toolbar';
import { useCalendar } from '@/components/DragAndDropCalendar/context';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconDragAndDrop } from '@/theme/icons';
import { MyEvent } from '@/types/calendar';
import { getColorWithOpacityFromToken } from '@/utils/color';
import { getCalendarDayTextColor } from '@/utils/events';
import rem from '@/utils/rem';
import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { useDndMonitor } from '@dnd-kit/core';
import { format, isSameDay } from 'date-fns';
import { motion } from 'framer-motion';
import { groupBy, isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type TimelineProps = {
  onUnplan: (event: MyEvent) => void;
  onClickEvent: (event: MyEvent) => void;
};

function Timeline({ onUnplan, onClickEvent }: TimelineProps) {
  const [highlightedTime, setHighlightedTime] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const { combinedEvents } = useCalendar();
  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineItemsRef = useRef<HTMLDivElement>(null);

  const formattedEvents = useMemo(
    () =>
      combinedEvents
        // TODO refactor to make this filter directly in calendar provider
        // for day / week view
        .filter((event) => isSameDay(event.start, selectedDate))
        .map((event) => {
          const formattedTime = event.allDay ? 'All Day' : format(event.start, 'h:mmaaa');
          return {
            ...event,
            formattedTime,
          };
        }),
    [combinedEvents, selectedDate],
  );

  // maue sure that all-day events are always at the top
  const sortedEvents = useMemo(
    () => [...formattedEvents.filter((e) => e.allDay), ...formattedEvents.filter((e) => !e.allDay)],
    [formattedEvents],
  );

  const groupedEvents = useMemo(() => groupBy(orderBy(sortedEvents, 'start', 'asc'), 'formattedTime'), [sortedEvents]);

  const checkEventsTime = useCallback(() => {
    const currentTime = new Date(); // Get the current date

    const foundTimeSlot = Object.entries(groupedEvents).find(([, events]) =>
      events.some((event) => {
        const eventStartTime = new Date(event.start);
        const eventEndTime = new Date(event.end);
        return !event.allDay && currentTime >= eventStartTime && currentTime <= eventEndTime;
      }),
    );
    setHighlightedTime(foundTimeSlot ? foundTimeSlot[0] : null);
  }, [groupedEvents]);

  useEffect(() => {
    // Update initially
    checkEventsTime();

    // Set up a recurring timer to check every 20 seconds
    const timerId = setInterval(checkEventsTime, 20000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timerId);
  }, [checkEventsTime, groupedEvents]);

  useDndMonitor({
    onDragStart() {
      setIsDragging(true);
    },
    onDragEnd() {
      setIsDragging(false);
    },
    onDragCancel() {
      setIsDragging(false);
    },
  });

  return (
    <Flex flexDirection="column" overflowY="auto" width="full" height="full">
      <DayToolbar
        date={selectedDate}
        position="sticky"
        zIndex="5"
        top="0"
        marginBottom={rem(22)}
        paddingBottom={rem(4)}
        backgroundColor="background-primary"
      />
      <motion.div
        layout
        initial={{ opacity: 0, zIndex: 2, display: 'flex', flexDirection: 'column', flex: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Flex ref={timelineRef} position="relative" flexDirection="column" flex="1" id="timeline">
          {isEmpty(groupedEvents) ? (
            <VStack
              alignItems="center"
              justifyContent="center"
              height="full"
              textAlign="center"
              backgroundColor={isDragging ? getColorWithOpacityFromToken('blue.950', '0.3') : 'inherit'}
            >
              <Icon as={IconDragAndDrop} width={rem(28)} height={rem(28)} />
              <Text textStyle="small" color="text-tertiary">
                Drag and drop an action here to begin scheduling
              </Text>
            </VStack>
          ) : (
            <>
              <Flex ref={timelineItemsRef} flexDirection="column">
                {Object.entries(groupedEvents).map(([key, events], index, arr) => (
                  <Flex
                    key={key}
                    width="full"
                    paddingTop={rem(6)}
                    paddingBottom={rem(6)}
                    borderTopWidth={rem(1)}
                    borderTopStyle="solid"
                    borderTopColor={highlightedTime === key ? 'cyan.400' : 'transparent'}
                    borderBottomWidth={index !== arr.length - 1 ? rem(1) : 0}
                    borderBottomStyle="solid"
                    borderBottomColor="stroke-primary"
                  >
                    <Flex minWidth={rem(64)}>
                      <Text
                        textStyle="small"
                        color={highlightedTime === key ? 'cyan.400' : getCalendarDayTextColor(events[0].start)}
                        fontWeight={highlightedTime === key ? 700 : 400}
                      >
                        {key}
                      </Text>
                    </Flex>
                    <Flex flexWrap="wrap" gap={rem(8)} width="full">
                      {events.map((event) => (
                        <ScheduledWeeklyTimeSlot
                          key={event.id}
                          cursor="pointer"
                          height={rem(32)}
                          title={event.title}
                          event={event}
                          onUnplan={onUnplan}
                          onClick={onClickEvent}
                        />
                      ))}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </>
          )}
        </Flex>
      </motion.div>
    </Flex>
  );
}

export default Timeline;
