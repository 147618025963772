import { COMPLETE } from '@/constants/action';
import { useLeveragedAndCommittedActions } from '@/services/action/hooks';
import { usePeople } from '@/services/people/hooks';
import { usePeopleStore } from '@/stores/usePeopleStore';
import { PersonListItem } from '@/types/people';
import React, { createContext, useContext, useDeferredValue, useEffect, useMemo } from 'react';

export type FilterOptions = {
  value: 'all' | 'open' | 'leverage' | 'commitment' | 'discussion';
  label: string;
};

export const selectFilterOptions: FilterOptions[] = [
  { value: 'all', label: 'View All' },
  { value: 'open', label: 'Open Items' },
  { value: 'leverage', label: 'Action Leveraged' },
  { value: 'commitment', label: 'Action Committed' },
  { value: 'discussion', label: 'Open Discussion' },
];

// Define the shape of your context state
interface PeopleContextState {
  people: PersonListItem[];
  filteredPeopleDeferred: PersonListItem[] | undefined;
  filterFn: any;
  searchQuery: string;
  filterSelected: 'all' | 'open' | 'leverage' | 'commitment' | 'discussion';
  setSearchQuery: (value: string) => void;
  setFilterSelected: (value: FilterOptions['value']) => void;
  allFilteredPeople: PersonListItem[] | undefined;
}

// Create a context with an initial state
const PeopleContext = createContext<PeopleContextState | undefined>(undefined);

// Create a provider component
interface PeopleProviderProps {
  children: React.ReactNode;
}

function PeopleProvider({ children }: PeopleProviderProps) {
  const { data: people } = usePeople();
  const { data: leveragedAndCommittedData } = useLeveragedAndCommittedActions();

  const peopleList = usePeopleStore((state) => state.peopleList);
  const searchQuery = usePeopleStore((state) => state.searchQuery);
  const filterSelected = usePeopleStore((state) => state.filterSelected);
  const updatePeopleList = usePeopleStore((state) => state.updatePeopleList);
  const updateSearchQuery = usePeopleStore((state) => state.updateSearchQuery);
  const updateFilterSelected = usePeopleStore((state) => state.updateFilterSelected);

  const filterFn = useMemo(() => {
    return {
      all: (p: PersonListItem) => p,
      open: (p: PersonListItem) =>
        p?.promises?.some((promise) => promise.kind === 'leverage' && promise?.action?.progressStatus !== COMPLETE) ||
        p?.promises?.some((promise) => promise.kind === 'commitment' && promise?.action?.progressStatus !== COMPLETE) ||
        ((p?.discussion_items?.length ?? 0 > 0) && p?.discussion_items?.some((item) => !item?.isCompleted)),
      leverage: (p: PersonListItem) =>
        p?.promises?.some((promise) => promise.kind === 'leverage' && promise?.action?.progressStatus !== COMPLETE),
      commitment: (p: PersonListItem) =>
        p?.promises?.some((promise) => promise.kind === 'commitment' && promise?.action?.progressStatus !== COMPLETE),
      discussion: (p: PersonListItem) =>
        (p?.discussion_items?.length ?? 0 > 0) && p?.discussion_items?.some((item) => !item?.isCompleted),
    };
  }, []);

  // Additional code for calculating filteredPeopleDeferred based on state
  const allFilteredPeople = useMemo(() => {
    // Both name and searchQuery are in lowercase for case-insensitive search
    const allPeople = people?.person?.filter((item) => item.name.toLowerCase().includes(searchQuery?.toLowerCase()));

    // Use the selected filter function from state.filterFn
    const selectedFilterFn = filterFn[filterSelected];

    // Apply the selected filter function to filter the people
    return selectedFilterFn ? allPeople?.filter(selectedFilterFn) : allPeople;

    // adding leveragedAndCommittedData to the dependancy array causes a re-calculation
    // when a person's leveraged action has changed status AS WELL as being deleted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterFn,
    filterSelected,
    people?.person,
    searchQuery,
    leveragedAndCommittedData?.committedActions?.length,
    leveragedAndCommittedData?.leveragedActions?.length,
  ]);

  // Update filteredPeopleDeferred when allFilteredPeople changes
  const filteredPeopleDeferred = useDeferredValue(allFilteredPeople);

  const contextValue: PeopleContextState = {
    people: peopleList,
    filterFn,
    searchQuery,
    setSearchQuery: updateSearchQuery,
    filterSelected,
    setFilterSelected: updateFilterSelected,
    filteredPeopleDeferred,
    allFilteredPeople,
  };

  useEffect(() => {
    if (people?.person) {
      updatePeopleList(people?.person ?? []);
    }
  }, [people?.person, updatePeopleList]);

  return <PeopleContext.Provider value={contextValue}>{children}</PeopleContext.Provider>;
}

// Custom hook to use the context state
function usePeopleList() {
  const context = useContext(PeopleContext);

  if (context === undefined) {
    throw Error('usePeopleList must be used within a PeopleProvider');
  }

  return context;
}

export { PeopleProvider, PeopleContext, usePeopleList };
