import ActionRow from '@/components/ActionRow';
import DetailsCard from '@/components/DetailsCard';
import DndContainer from '@/components/DndContainer';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import QuickActionCreator from '@/components/QuickActionCreator';
import { CATEGORIES_ICONS } from '@/constants/category';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { RoutesList } from '@/routes/routesList';
import { useUpdateAction } from '@/services/action/hooks';
import { useDeleteBlock, useUpdateActionsCategoryOrder } from '@/services/myPlan/hooks';
import { useActionsCategories } from '@/stores/useActionsCategories';
import { IconCalendar, IconFire } from '@/theme/icons';
import { Action, ActionResponse } from '@/types/actions';
import { Category } from '@/types/category';
import {
  deleteAction,
  getActionById,
  getActionsByBlockId,
  getActionsByCategoryId,
  getIncompleteActions,
} from '@/utils/action';
import { getColorFromToken } from '@/utils/color';
import { isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, Flex, HStack, Link, Spinner, Text, VStack, useToast } from '@chakra-ui/react';
import { DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useAnimation } from 'framer-motion';
import { isEmpty } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { NavLink as RouterLink } from 'react-router-dom';

type CategoryWithActions = {
  completedActions: Action[];
  incompleteActions: Action[];
} & Category;

type Props = {
  category: Category | undefined;
  actions: ActionResponse | undefined;
  isCategoriesLoading: boolean;
  isLoadingActions: boolean;
  getSortedActionsByCategoryId: (categoryId: string) => Action[];
};

function RightSection({
  category,
  actions,
  isCategoriesLoading,
  isLoadingActions,
  getSortedActionsByCategoryId,
}: Props) {
  const toast = useToast();
  const actionCreatorOptionsAnimation = useAnimation();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const isDarkMode = useIsDarkMode();

  const selectedCategory = useActionsCategories((state) => state.selectedCategory);
  const updateActionsCategoryOrder = useUpdateActionsCategoryOrder();

  const deleteBlock = useDeleteBlock();

  const ItemOverlay = useMemo(() => {
    if (!activeId || !actions?.action) {
      return <></>;
    }

    const item = getActionById(actions.action, activeId.toString());

    if (!item) {
      return <></>;
    }

    return createPortal(
      <DragOverlay dropAnimation={{ easing: 'ease-in' }}>
        <ActionRow action={item} idx={activeItemIndex + 1} />
      </DragOverlay>,
      document.body,
    );
  }, [actions?.action, activeId, activeItemIndex]);

  const SingleCategoryContainer = useCallback(
    (category: CategoryWithActions) => {
      if (!category) return <></>;

      if (isLoadingActions) {
        return (
          <Flex justifyContent="center" width="full">
            <Spinner />
          </Flex>
        );
      }

      return (
        <DroppableContainer
          id={category?.id}
          key={category?.id}
          payload={{
            categoryId: category?.id,
          }}
          as={VStack}
          alignItems="flex-start"
          width="full"
          borderRadius={rem(16)}
        >
          <SortableContext items={category?.incompleteActions}>
            <ActionList
              width="full"
              emptyListComponent={
                <DroppableContainer
                  id="captureList"
                  marginTop={rem(12)}
                  payload={{ containerId: 'captureList', category }}
                >
                  <EmptyActionList message="No incomplete actions" />
                </DroppableContainer>
              }
              isEmpty={isEmpty(category?.incompleteActions)}
            >
              {category?.incompleteActions.map((action, index) => (
                <ActionListItem
                  key={action.id}
                  item={action}
                  index={index}
                  onDeleteAction={deleteAction}
                  dndPayload={{
                    containerId: `captureList-${category?.id}`,
                    categoryId: action.categoryId,
                    category,
                    index,
                    projectId: action?.projectId,
                    // blockId: action?.blockId,
                  }}
                />
              ))}
            </ActionList>
          </SortableContext>
        </DroppableContainer>
      );
    },
    [isLoadingActions],
  );

  // Drag and Drop methods
  const updateCaptureListActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          categoryOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateActionsCategoryOrder.mutate(mutationPayload);
    },
    [updateActionsCategoryOrder],
  );

  const updateActionCategory = useUpdateAction({
    onError: (error): void => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleActionNewCategory = useCallback(
    (actionId: string, categoryId: string, blockId: string | null) => {
      const actionsByCategory = getActionsByCategoryId(actions?.action ?? [], categoryId);

      let shouldDeleteBlock = false;

      if (blockId) {
        const blockActions = getActionsByBlockId(blockId, actions?.action ?? []);

        if (blockActions?.length === 1) {
          shouldDeleteBlock = true;
        }
      }

      updateActionCategory.mutate({
        actionId,
        set: { categoryId, categoryOrder: actionsByCategory.length + 1, blockId: null, blockOrder: null },
      });

      if (shouldDeleteBlock && blockId) {
        deleteBlock.mutate({ blockId });
      }
    },
    [actions?.action, deleteBlock, updateActionCategory],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemIndex(active?.data?.current?.index);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      setActiveItemIndex(0);
      return;
    }

    const activeIndex = active?.data?.current?.index;
    const overIndex = over?.data?.current?.index;

    const activeContainerId = active?.data?.current?.containerId;
    const overContainerId = over?.data?.current?.containerId;

    const activeCategoryId = active?.data?.current?.categoryId;
    const overCategoryId = over?.data?.current?.categoryId;

    const activeBlockId = active?.data?.current?.blockId;

    const activeProjectId = active?.data?.current?.projectId;
    const overProjectId = over?.data?.current?.projectId;

    const notNullProjectId = ![null, undefined].includes(activeProjectId, overProjectId);

    if (notNullProjectId && activeProjectId !== overProjectId && activeCategoryId !== overCategoryId) {
      setActiveItemIndex(0);

      return;
    }

    if (activeContainerId === overContainerId) {
      const actions = getIncompleteActions(getSortedActionsByCategoryId(active?.data?.current?.categoryId));
      updateCaptureListActionsOrder(arrayMove(actions, activeIndex, overIndex));
    }

    if (activeContainerId !== overContainerId) {
      handleActionNewCategory(active.id.toString(), overCategoryId, activeBlockId);
    }

    setActiveId(null);
    setActiveItemIndex(0);
  };

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemIndex(0);
  };
  // / Drag and Drop methods

  if (isCategoriesLoading && !category) {
    return (
      <Flex justifyContent="center" width="full">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {!isUncategorized(category) && category?.ultimatePurpose && (
        <DetailsCard
          paddingX={rem(24)}
          paddingTop={rem(24)}
          paddingBottom={rem(32)}
          color={isDarkMode ? category?.color : getColorFromToken(category?.color, undefined, '100')}
          icon={IconFire}
          sectionTitle="Remember Your Ultimate Purpose"
          height="fit-content"
          border="1px solid rgba(67, 78, 97, 0.30)"
          opacized
        >
          <Text as="p" textStyle="h4SatBlack" color="text-primary">
            {category.ultimatePurpose}
          </Text>
        </DetailsCard>
      )}

      <DetailsCard
        paddingX={rem(24)}
        paddingTop={rem(24)}
        paddingBottom={rem(32)}
        color={isDarkMode ? category?.color : getColorFromToken(category?.color, undefined, '100')}
        icon={CATEGORIES_ICONS?.[category?.icon ?? 'uncategorized']}
        sectionTitle="This Week's Actions and Outcomes"
        rightButton={
          <Link
            as={RouterLink}
            padding={rem(10)}
            border="none"
            borderRadius={4}
            _hover={{
              textDecoration: 'none',
            }}
            backgroundColor="background-primary"
            to={RoutesList.MyPlanPage}
          >
            <HStack width="auto">
              <Box as={IconCalendar} width={rem(19)} height={rem(19)} strokeWidth={2} />

              <Text textStyle="smallBlack" width="max-content" color="text-primary">
                Plan My Week
              </Text>
            </HStack>
          </Link>
        }
        height="fit-content"
        border="1px solid rgba(67, 78, 97, 0.30)"
        opacized
      >
        <QuickActionCreator
          category={category}
          selectedCategoryId={selectedCategory?.id}
          actionCreatorOptionsAnimation={actionCreatorOptionsAnimation}
        />

        <DndContainer onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
          {SingleCategoryContainer(category as CategoryWithActions)}

          {ItemOverlay}
        </DndContainer>
      </DetailsCard>
    </>
  );
}

export default memo(RightSection);
