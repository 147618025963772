import ScheduledTime from '@/components/ActionModal/ScheduledTime';
import StyledModal from '@/components/StyledModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconClock } from '@/theme/icons';
import rem from '@/utils/rem';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

type ScheduleEventModalProps = {
  isOpen: boolean;
  onSave: (time: Date) => void;
  onClose: () => void;
};

function ScheduleEventModal({ isOpen, onSave, onClose }: ScheduleEventModalProps) {
  const [time, setTime] = useState<Date | null>(null);

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const onChangeTime = (time: Date | null) => {
    setTime(time);
  };

  const handleOnSave = useCallback(() => {
    if (!time) {
      return;
    }

    time.setDate(selectedDate.getDate());
    time.setMonth(selectedDate.getMonth());
    time.setFullYear(selectedDate.getFullYear());

    onSave(time);
  }, [onSave, selectedDate, time]);

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      title="What time would you like to schedule this action for?"
    >
      <ModalBody>
        <ScheduledTime
          scheduledTime={time}
          selectedDate={selectedDate}
          updateScheduledTime={onChangeTime}
          maxWidth={time === null ? 'auto' : rem(152)}
          leftIcon={<IconClock color="text-primary" width={rem(20)} height={rem(20)} />}
          color="text-primary"
          textStyle="large"
          fontWeight="500"
          variant="secondary"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} size="lg" variant="secondary">
          Cancel
        </Button>
        <Button isDisabled={!time} onClick={handleOnSave} size="lg" variant="primary">
          Save
        </Button>
      </ModalFooter>
    </StyledModal>
  );
}

export default ScheduleEventModal;
