import TimeInput from '@/components/TimeInput';
import { IconClear } from '@/theme/icons';
import { formatDateToString, localDateToUTC, utcToLocalDate } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, ButtonProps, HStack } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

export type ScheduledTimeProps = {
  scheduledTime: Date | null;
  selectedDate: Date;
  removeTimeOnClickHeader?: boolean;
  updateScheduledTime: (date: Date | null) => void;
} & Omit<ButtonProps, 'tabIndex'>;

const ScheduledTime = ({ scheduledTime, selectedDate, updateScheduledTime, ...rest }: ScheduledTimeProps) => {
  const time = scheduledTime ?? new Date();

  const hours = time?.getHours().toString().padStart(2, '0');
  const minutes = time?.getMinutes().toString().padStart(2, '0');
  const formattedTime = hours + ':' + minutes;

  const [timeValue, setTimeValue] = useState<string | undefined>(formattedTime);

  const handleSetTime = useCallback(() => {
    if (scheduledTime) return;

    updateScheduledTime(new Date());
  }, [scheduledTime, updateScheduledTime]);

  const onTimeChange = useCallback(
    (dateString: string) => {
      setTimeValue(dateString);

      updateScheduledTime(
        utcToLocalDate(
          formatDateToString(selectedDate),
          localDateToUTC(`${formatDateToString(selectedDate)} ${dateString}`).scheduledTime,
        ),
      );
    },
    [selectedDate, updateScheduledTime],
  );

  const onResetTime = useCallback(() => {
    const hours = new Date()?.getHours().toString().padStart(2, '0');
    const minutes = new Date()?.getMinutes().toString().padStart(2, '0');
    const formattedTime = hours + ':' + minutes;

    setTimeValue(formattedTime);

    updateScheduledTime(null);
  }, [updateScheduledTime]);

  return (
    <HStack
      maxWidth="auto"
      backgroundColor={scheduledTime ? 'background-primary' : 'transparent'}
      paddingX={scheduledTime ? rem(8) : 0}
    >
      {!scheduledTime && (
        <Button
          padding={rem(16)}
          isActive={Boolean(scheduledTime)}
          isDisabled={!!scheduledTime}
          onClick={handleSetTime}
          size="lg"
          variant="outline"
          {...rest}
        >
          Set Time
        </Button>
      )}

      {scheduledTime && (
        <>
          <TimeInput
            value={timeValue}
            hour12Format
            fullTimeDropdown
            // showTimeChevron
            showAMPMDropdown
            minutesIncrement={15}
            onChange={onTimeChange}
          />

          <Button
            minWidth={rem(16)}
            height={rem(16)}
            padding="0"
            borderRadius="99"
            _hover={{
              backgroundColor: 'background-queternary',
            }}
            aria-label="Remove Time"
            backgroundColor="gray.900"
            onClick={onResetTime}
          >
            <Box as={IconClear} width={rem(8)} height={rem(8)} color="white.500" />
          </Button>
        </>
      )}
    </HStack>
  );
};

export default ScheduledTime;
