import { IconChevronLeft, IconChevronRight } from '@/theme/icons';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, Button, ButtonProps, Flex, Text, useColorMode } from '@chakra-ui/react';
import { useCallback } from 'react';

type Props = {
  onClick: () => void;
  buttonProps?: ButtonProps;
  innerText?: string;
  chevronLeft?: boolean;
};

const FullCaptureListFAB = ({ onClick, buttonProps, innerText, chevronLeft = false }: Props) => {
  const { colorMode } = useColorMode();
  const isDarkTheme = colorMode === 'dark';
  const bgOpacized = getColorFromToken(isDarkTheme ? 'cyan.950' : 'cyan.100', '0.70');

  const onInternalClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <Button
      position="fixed"
      zIndex="sticky"
      bottom={rem(50)}
      flexDirection={chevronLeft ? 'row-reverse' : 'row'}
      gap={rem(8)}
      minWidth={rem(28)}
      height={rem(28)}
      margin={0}
      padding={innerText ? `${rem(7)} ${rem(chevronLeft ? 11 : 2)} ${rem(7)} ${rem(chevronLeft ? 2 : 11)}` : 0}
      borderRadius="full"
      _hover={{
        backgroundColor: bgOpacized,
      }}
      backgroundColor={bgOpacized}
      onClick={onInternalClick}
      variant="tertiary"
      {...buttonProps}
    >
      {innerText && (
        <Text textStyle="xsmallBlack" textTransform="uppercase">
          {innerText}
        </Text>
      )}

      <Flex
        alignItems="center"
        justifyContent="center"
        width={rem(24)}
        height={rem(24)}
        padding={rem(5)}
        borderRadius="full"
        backgroundColor="cyan.400"
      >
        <Box as={chevronLeft ? IconChevronLeft : IconChevronRight} color="blue.1000" />
      </Flex>
    </Button>
  );
};

export default FullCaptureListFAB;
