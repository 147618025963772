import { ReactNode, createContext, useContext } from 'react';

interface FeatureFlags {
  [key: string]: boolean;
}

interface FeatureFlagContextProps {
  [key: string]: FeatureFlags;
}

interface FeatureFlagProviderProps {
  environment: string;
  children: ReactNode;
}

const FeatureFlagContext = createContext<FeatureFlags>({});

const featureFlags: FeatureFlagContextProps = {
  // Define your feature flags for each environment here
  staging: {
    darkTheme: true,
    M7ComingSoon: false,
  },
  uat: {
    darkTheme: true,
    M7ComingSoon: false,
  },
  production: {
    darkTheme: true,
    M7ComingSoon: false,
  },
};

const FeatureFlagProvider = ({ environment, children }: FeatureFlagProviderProps) => {
  const currentFlags = featureFlags[environment] || {};

  return <FeatureFlagContext.Provider value={currentFlags}>{children}</FeatureFlagContext.Provider>;
};

const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error('useFeatureFlags should be used within a FeatureFlagProvider.');
  }

  return context;
};

export { FeatureFlagProvider, FeatureFlagContext, useFeatureFlags };
