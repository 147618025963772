import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { IconCheckmarkSingle, IconPlus } from '@/theme/icons';
import rem from '@/utils/rem';
import { Box, Button, SpaceProps, Text } from '@chakra-ui/react';
import { memo } from 'react';

type Props = {
  onClick?: () => void;
  isActive: boolean;
} & SpaceProps;
function ScheduleButton({ onClick, isActive, ...rest }: Props) {
  const isDailyPlanPage = useIsDailyPlanPage();
  const dayOrWeek = isDailyPlanPage ? 'day' : 'week';

  return (
    <Button
      width={rem(90)}
      height={rem(24)}
      minHeight={rem(24)}
      background="background-primary"
      borderColor={isActive ? 'background-secondary' : 'stroke-primary'}
      borderRadius={rem(6)}
      _hover={{
        backgroundColor: 'background-secondary',
        cursor: 'pointer',
      }}
      _disabled={{
        _hover: {
          backgroundColor: 'background-secondary',
          cursor: 'default',
        },
      }}
      cursor={isActive ? 'default' : 'inherit'}
      backgroundColor={isActive ? 'background-secondary' : 'background-primary'}
      isDisabled={isActive}
      leftIcon={
        !isActive ? (
          <Box as={IconPlus} width={rem(10)} height={rem(10)} color="text-primary" />
        ) : (
          <Box as={IconCheckmarkSingle} width={rem(10)} height={rem(10)} color="text-primary" />
        )
      }
      onClick={onClick}
      paddingX="0"
      variant="secondary"
      {...rest}
    >
      <Text textStyle="small" color="text-primary">
        {!isActive ? `This ${dayOrWeek}` : 'Added'}
      </Text>
    </Button>
  );
}

export default memo(ScheduleButton);
