import ActionRow from '@/components/ActionRow';
import CategoriesMenu from '@/components/CategoriesMenu';
import { BlockFormSchema } from '@/components/CreateBlockModal';
import Input from '@/components/Input';
import ProjectsMenu from '@/components/ProjectsMenu';
import PurposeSelect from '@/components/PurposeSelect';
import StyledModal from '@/components/StyledModal';
import { CHANGE_BLOCK_CATEGORY, UPDATE_BLOCK } from '@/gql/block';
import { UpdateBlockResponse } from '@/gql/block/types';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useProjects } from '@/services/project/hooks';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconTrash } from '@/theme/icons';
import { Block } from '@/types/block';
import { getCategoryById } from '@/utils/category';
import { getProjectById } from '@/utils/project';
import rem from '@/utils/rem';
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
  VStack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type FormData = {
  result: string;
  purpose: string;
};

type Props = {
  block: Block;
  isOpen: boolean;
  readonlyCategory?: boolean;
  weeklyPlanId?: string;
  onUpdateBlock?: (block: Block) => void;
  onDeleteBlock: (block?: Block) => void;
  onCancel: () => void;
  readonlyTooltipLabel?: string;
};

function EditBlockModal({
  readonlyCategory = false,
  block,
  isOpen,
  onCancel: cancelEditBlock,
  onDeleteBlock,
  onUpdateBlock,
  weeklyPlanId,
  readonlyTooltipLabel = undefined,
}: Props) {
  const formRef = useRef<HTMLFormElement>(null);
  const { data: categories } = useCategories();
  const { data: projects } = useProjects();

  const [isLoading, setIsLoading] = useBoolean();
  const isDailyPlanPage = useIsDailyPlanPage();

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>(block.categoryId);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(block.projectId);

  const selectedProject = useMemo(
    () => getProjectById(projects?.project ?? [], selectedProjectId ?? ''),
    [selectedProjectId, projects?.project],
  );

  const toast = useToast();

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const selectedCategory = useMemo(
    () => getCategoryById(categories?.category ?? [], selectedCategoryId),
    [categories?.category, selectedCategoryId],
  );

  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    defaultValues: block,
    resolver: zodResolver(BlockFormSchema),
    mode: 'onChange',
  });

  const updateBlock = useMutation({
    mutationFn: async ({ id, purpose, result, categoryId, isCompleted, scheduledDate }: Block) => {
      // !TODO: only run this if the category has actually changed
      await fetchData(CHANGE_BLOCK_CATEGORY, {
        blockId: id,
        categoryId,
      });

      return fetchData<UpdateBlockResponse>(UPDATE_BLOCK, {
        id,
        purpose,
        result,
        scheduledDate,
        weeklyPlanId,
        isCompleted,
      });
    },

    onSuccess: (data) => {
      reset();
      onUpdateBlock && onUpdateBlock(data.updateBlockByPk);

      toast({
        title: 'Block updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const onSubmit = useCallback(
    async ({ result, purpose }: FormData) => {
      setIsLoading.on();
      if (selectedCategory) {
        await updateBlock.mutateAsync({
          id: block.id,
          result,
          purpose,
          categoryId: selectedCategory.id,
          category: selectedCategory,
          weeklyPlanId: String(weeklyPlanId),
          scheduledDate: isDailyPlanPage ? selectedDate : null,
          isCompleted: block.isCompleted,
        });
        setIsLoading.off();
      }
    },
    [
      setIsLoading,
      updateBlock,
      block.id,
      block.isCompleted,
      weeklyPlanId,
      selectedDate,
      isDailyPlanPage,
      selectedCategory,
    ],
  );

  const submitForm = () => formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

  const onCancel = useCallback(() => {
    reset();
    cancelEditBlock();
  }, [cancelEditBlock, reset]);

  const handleDeleteBlock = useCallback(() => {
    onDeleteBlock(block);
  }, [block, onDeleteBlock]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('result'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel}>
      <ModalHeader justifyContent="space-between" display="flex">
        Edit Block{' '}
        <CategoriesMenu
          shouldCreateCategory={false}
          category={selectedCategory}
          onSelectCategory={(category) => setSelectedCategoryId(category.id)}
          readonly={readonlyCategory || !!block.projectId}
          readonlyTooltipLabel={
            readonlyTooltipLabel ?? 'The category of this block cannot be changed because it is included in a project'
          }
        />
      </ModalHeader>
      <ModalBody>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems="normal" width="full" spacing={rem(24)}>
            <Controller
              name="result"
              control={control}
              render={({ field: { ref, value, onChange } }) => (
                <Input
                  ref={ref}
                  label="Result"
                  placeholder="A specific, measurable outcome that you are committed to achieving"
                  type="text"
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  error={errors?.result?.message}
                  _placeholder={{
                    color: 'text-tertiary',
                    opacity: 0.3,
                    fontSize: '3xl',
                    fontWeight: 500,
                  }}
                  fontSize="3xl"
                  fontWeight={500}
                  paddingBottom={rem(16)}
                  errorMessageProps={{
                    color: 'red.500',
                    textStyle: 'large',
                  }}
                />
              )}
            />

            {selectedCategory && (
              <Controller
                name="purpose"
                control={control}
                render={({ field }) => (
                  <PurposeSelect
                    {...field}
                    category={selectedCategory}
                    error={errors?.purpose?.message}
                    placeholder="The deeper, emotional reason behind why you want to achieve this result"
                  />
                )}
              />
            )}

            {selectedProject && (
              <>
                <ProjectsMenu
                  variant="bordered"
                  categoryId={selectedCategory?.id}
                  project={selectedProject ?? null}
                  setSelectedProject={(project) => {
                    setSelectedProjectId(project?.id);
                  }}
                  readonly
                />

                <Divider opacity={1} borderColor="stroke-primary" />
              </>
            )}

            <VStack alignItems="start" gap={rem(24)}>
              <Text margin={0} color="text-tertiary" fontSize="sm" fontWeight="900" textTransform="uppercase">
                Actions
              </Text>

              <VStack overflowY="scroll" width="full" maxHeight={rem(200)}>
                {block.actions?.map((action) => (
                  <ActionRow
                    key={action.id}
                    action={action}
                    backgroundColor="background-primary"
                    readOnly
                    hideButtons
                  />
                ))}
              </VStack>
            </VStack>
          </VStack>
        </form>
      </ModalBody>

      <ModalFooter justifyContent="space-between" display="flex">
        <IconButton
          aria-label="Delete Block"
          icon={<Icon as={IconTrash} width={rem(20)} height={rem(20)} />}
          onClick={handleDeleteBlock}
          size="lg"
          variant="secondary"
        />

        <HStack gap={rem(16)}>
          <Button onClick={onCancel} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button
            isDisabled={!isDirty || !isValid || updateBlock.isLoading}
            isLoading={isLoading}
            onClick={submitForm}
            size="lg"
            variant="primary"
          >
            Update Block
          </Button>
        </HStack>
      </ModalFooter>
    </StyledModal>
  );
}

export default EditBlockModal;
