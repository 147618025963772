import { CATEGORIES_ICONS } from '@/constants/category';
import { RoutesList } from '@/routes/routesList';
import { IconUncategorizedLogo } from '@/theme/icons';
import { Category } from '@/types/category';
import { resolveCategoryImageByName } from '@/utils/category';
import { generateLinearGradient, getColorFromToken } from '@/utils/color';
import { fixUncategorizedName, isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';

type Props = {
  category: Category;
};

const CategoryManagerOverviewCard = ({ category }: Props) => {
  return (
    <Link
      as={RouterLink}
      position="relative"
      minHeight={rem(246)}
      background={
        category.selectedImageUrl
          ? `linear-gradient(180deg, ${getColorFromToken(category.color, '0')} 0%, ${getColorFromToken(
              category.color,
              '0.35',
            )} 60%), linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), url(${resolveCategoryImageByName(
              `${category.selectedImageUrl}&w=800&dpr=2&ar=3%3A2&fit=crop&crop=faces%2Cedges&q=65`,
              // 800 is closest to max cell size which is defined by grid based on the page width
              // AR 3:2 is closest to the most square-like image (can be more elongated due to grid layout and on mobile)
            )}) center / cover no-repeat`
          : `linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%), ${generateLinearGradient(
              category?.color ?? 'gray.300',
              getColorFromToken(category?.color, '0.2', '900'),
              -360,
            )}`
      }
      borderBottom={`${rem(7)} solid`}
      borderBottomColor={category.color}
      borderRadius={rem(8)}
      _hover={{
        textDecoration: 'none',
      }}
      backgroundColor="background-tertiary"
      to={`${RoutesList.CategoryManagerPage}/${category.id}`}
    >
      {isUncategorized(category) && <Box as={IconUncategorizedLogo} position="absolute" right={0} bottom={0} />}
      <VStack alignItems="start" justifyContent="end" width="full" height={rem(246)} padding={rem(24)}>
        <VStack alignItems="start" gap={rem(12)}>
          <Flex
            alignItems="center"
            justifyContent="center"
            width={rem(32)}
            minWidth={rem(32)}
            height={rem(32)}
            minHeight={rem(32)}
            borderRadius="full"
            backgroundColor={category.color}
          >
            <Icon
              as={CATEGORIES_ICONS[category?.icon ?? 'uncategorized']}
              width={rem(19)}
              height={rem(19)}
              color="blue.1000"
            />
          </Flex>

          <Text textStyle="h3BarSB" width="full" color="white" textTransform="uppercase" noOfLines={1}>
            {fixUncategorizedName(category.name)}
          </Text>
        </VStack>

        <VStack alignItems="start" gap={rem(3)} width="full">
          <Text textStyle="large" width="full" color="white" noOfLines={1}>
            {isUncategorized(category) ? (
              'Capture your actions, outcomes, and results'
            ) : (
              <>{category.ultimateVision ?? 'No Ultimate Vision Added'}</>
            )}
          </Text>
        </VStack>
      </VStack>
    </Link>
  );
};

export default CategoryManagerOverviewCard;
