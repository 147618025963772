import AddActionButton from '@/components/AddActionButton';
import { SortableItemContainer } from '@/components/DragAndDrop';
import EmptyActionList from '@/components/EmptyActionList';
import ManageDiscussionPointModal from '@/components/ManageDiscussionPointModal';
import { DiscussionPointListProvider } from '@/contexts/DiscussionPointsList';
import DiscussionPointItem from '@/pages/People/PersonDetailView/DiscussionPointItem';
import { IconChatBubble } from '@/theme/icons';
import { DiscussionPoint } from '@/types/discussionPoints';
import { EMPTY_STATE_MIN_HEIGHT, HEADER_HEIGHT } from '@/utils/people';
import rem from '@/utils/rem';
import { Badge, Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { SortableContext } from '@dnd-kit/sortable';
import { useState } from 'react';

type Props = {
  discussionPoints: DiscussionPoint[];
  personId: string;
  isWithCounter?: boolean;
};

function DiscussionPoints({ discussionPoints, personId, isWithCounter = true }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isOpen: isCreateDiscussionOpen,
    onOpen: onCreateDiscussionOpen,
    onClose: onCreateDiscussionClose,
  } = useDisclosure({ id: 'discussionPoint' });

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Box width="full">
      <Flex alignItems="center" justifyContent="space-between">
        <Text as="h4" textStyle="large" color="text-primary">
          Discussion Points
          {isWithCounter && (
            <Badge margin={`${rem(-4)} 0 0 ${rem(16)}`} padding={`${rem(4)} ${rem(8)}`} color="gray.50" rounded="full">
              {discussionPoints.length}
            </Badge>
          )}
        </Text>

        <AddActionButton
          variant="secondary"
          onExternalClick={onCreateDiscussionOpen}
          tooltipText="Add a new discussion point"
        />
      </Flex>

      <Box marginTop={rem(21)}>
        {discussionPoints.length ? (
          <DiscussionPointListProvider items={discussionPoints}>
            <SortableContext items={discussionPoints} disabled={isOpen}>
              {discussionPoints.map((discussionPoint, index) => (
                <SortableItemContainer
                  key={discussionPoint.id}
                  id={discussionPoint.id}
                  payload={{ containerId: 'discussionPoint', index }}
                >
                  <DiscussionPointItem
                    id={discussionPoint.id}
                    body={discussionPoint.body}
                    duration={discussionPoint.duration}
                    isCompleted={discussionPoint.isCompleted}
                    order={index + 1}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                  />
                </SortableItemContainer>
              ))}
            </SortableContext>
          </DiscussionPointListProvider>
        ) : (
          <EmptyActionList
            minHeight={`calc(${EMPTY_STATE_MIN_HEIGHT} - ${HEADER_HEIGHT})`}
            withIcons
            icon={IconChatBubble}
            title="You've got a clean slate! Right now, there are no discussion points set"
            message="Unlock your potential by capturing those vital discussion points! Don't just let them slip away; make a note and follow up. It's these details that can propel you to greatness!"
          />
        )}
      </Box>

      <ManageDiscussionPointModal
        isOpen={isCreateDiscussionOpen}
        onClose={onCreateDiscussionClose}
        personId={personId}
        count={discussionPoints?.length}
      />
    </Box>
  );
}

export default DiscussionPoints;
