const RoutesList = {
  HomePage: '/',
  WeeklyCapturePage: '/my-plan/weekly/capture',
  CapturePage: '/my-plan/capture',
  MyPlanPage: '/my-plan/weekly/plan',
  SchedulePage: '/my-plan/weekly/schedule',
  CelebratePage: '/my-plan/weekly/celebrate',
  MyPlanDailyPage: '/my-plan/daily/plan',
  ProjectsPage: '/projects',
  ProjectDetailPage: '/projects/:id',
  PeoplePage: '/people',
  PersonPage: '/people/:id',
  CategoryManagerPage: '/category-manager',
  CategoryManagerDetailsPage: '/category-manager/:id',
  LeverageRequestsPage: '/leverage-requests/:personToken',
};

export { RoutesList };
