import { DoneAudio, HallelujaAudio } from '@/constants/audio';
import useAudio from '@/hooks/useAudio';
import { useUserInfo } from '@/services/user/hooks';
import { createContext, useContext, useMemo } from 'react';

interface ProviderProps {
  children: React.ReactNode;
}

interface SoundEffectsContextStore {
  playCompleteAudio: () => void;
  playHallelujaAudio: () => void;
}

export const SoundEffectsContext = createContext<SoundEffectsContextStore>({
  playCompleteAudio: () => {},
  playHallelujaAudio: () => {},
});

const SoundEffectsProvider = ({ children }: ProviderProps) => {
  const { data: userInfo } = useUserInfo();

  const { playAudio: playCompleteAudio } = useAudio(DoneAudio);
  const { playAudio: playHallelujaAudio } = useAudio(HallelujaAudio);

  const soundEffects = useMemo(() => {
    return userInfo?.me?.soundsEnabled
      ? { playCompleteAudio, playHallelujaAudio }
      : { playCompleteAudio: () => {}, playHallelujaAudio: () => {} };
  }, [userInfo?.me?.soundsEnabled, playCompleteAudio, playHallelujaAudio]);

  return <SoundEffectsContext.Provider value={soundEffects}>{children}</SoundEffectsContext.Provider>;
};

export default SoundEffectsProvider;

export function useSoundEffects() {
  return useContext(SoundEffectsContext);
}
