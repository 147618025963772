import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconChevronLeft, IconChevronRight } from '@/theme/icons';
import { isThisWeek, startOfTheWeek } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, HStack, IconButton, Text, Tooltip, chakra } from '@chakra-ui/react';
import { addDays, addWeeks, startOfToday, subDays, subWeeks } from 'date-fns';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { Navigate } from 'react-big-calendar';

import InnerDate from './innerDate';

type Props = {
  selectedDate: Date;
};

const MotionDiv = chakra(motion.div);

const WeekNavigator = ({ selectedDate }: Props) => {
  const updateSelectedDate = useCalendarMonthlyStore((state) => state.updateSelectedDate);
  const isDailyPlanPage = useIsDailyPlanPage();

  const isCurrentWeek = isThisWeek(startOfTheWeek(selectedDate));

  const onGoToThisWeek = useCallback(() => {
    updateSelectedDate(startOfToday());
  }, [updateSelectedDate]);

  const onHandlePrevNextCalendarNavigation = useCallback(
    (date: Date, action: 'PREV' | 'NEXT' | 'TODAY') => {
      switch (action) {
        case Navigate.PREVIOUS: {
          const newDate = !isDailyPlanPage ? subWeeks(date, 1) : subDays(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        case Navigate.NEXT: {
          const newDate = !isDailyPlanPage ? addWeeks(date, 1) : addDays(date, 1);
          updateSelectedDate(newDate);

          return newDate;
        }

        default: {
          updateSelectedDate(date);
          return date;
        }
      }
    },
    [updateSelectedDate, isDailyPlanPage],
  );

  return (
    <HStack justifyContent="center" gap={rem(8)} width="fit-content" minWidth="fit-content" height={rem(28)}>
      <InnerDate selectedDate={selectedDate} />

      <HStack gap={rem(4)} height="full">
        <Tooltip label={!isDailyPlanPage ? 'Previous week' : 'Previous day'} placement="top">
          <IconButton
            width={rem(28)}
            height="full"
            border="none"
            aria-label={!isDailyPlanPage ? 'Previous week' : 'Previous day'}
            icon={<Box as={IconChevronLeft} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.PREVIOUS)}
            size="xs"
            variant="secondary"
          />
        </Tooltip>

        <Tooltip label={!isDailyPlanPage ? 'Next week' : 'Next day'} placement="top">
          <IconButton
            width={rem(28)}
            height="full"
            border="none"
            aria-label={!isDailyPlanPage ? 'Next week' : 'Next day'}
            icon={<Box as={IconChevronRight} />}
            onClick={() => onHandlePrevNextCalendarNavigation(selectedDate, Navigate.NEXT)}
            size="xs"
            variant="secondary"
          />
        </Tooltip>

        {!isCurrentWeek && (
          <MotionDiv
            initial="initialState"
            animate="animateState"
            exit="exitState"
            height="full"
            variants={{
              initialState: {
                opacity: 0,
              },
              animateState: {
                opacity: 1,
              },
              exitState: {
                opacity: 0,
              },
            }}
          >
            <Button
              height="full"
              marginLeft={rem(8)}
              border="none"
              onClick={onGoToThisWeek}
              paddingX={rem(8)}
              variant="secondary"
            >
              <Text textStyle="small" color="text-primary">
                Show This Week
              </Text>
            </Button>
          </MotionDiv>
        )}
      </HStack>
    </HStack>
  );
};

export default WeekNavigator;
