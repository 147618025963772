import { isCurrentPageActive } from '@/utils/index';
import rem from '@/utils/rem';
import { Box, HStack, MenuItem, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

type Props = {
  href: string;
  label: string;
  icon: React.FunctionComponent;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const DropDownNavLink = ({ href, label, icon: Icon, onClick }: Props) => {
  const currentActivePage = href && isCurrentPageActive(href);

  return (
    <MenuItem
      as={NavLink}
      width="full"
      padding={`${rem(16)} ${rem(48)} ${rem(16)} ${rem(16)}`}
      _hover={{
        backgroundColor: 'background-secondary',
        textDecoration: 'none',
      }}
      _active={{
        backgroundColor: 'background-secondary',
      }}
      _focusVisible={{
        backgroundColor: { base: 'background-primary', lg: 'background-secondary' },
      }}
      backgroundColor={currentActivePage ? 'background-secondary' : 'transparent'}
      onClick={onClick}
      to={href}
    >
      <HStack as="span">
        <Box as={Icon} color={currentActivePage ? 'cyan.400' : 'text-primary'} />

        <Text
          textStyle={{
            base: 'medium',
            lg: 'large',
          }}
          fontWeight={currentActivePage ? '900 !important' : 500}
        >
          {label}
        </Text>
      </HStack>
    </MenuItem>
  );
};

export default DropDownNavLink;
