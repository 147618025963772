import Clock from '@/components/ActionRow/Clock';
import EllipsisTooltipText from '@/components/ActionRow/EllipsisTooltipText';
import DeleteActionModal from '@/components/DeleteActionModal';
import DuplicateActionModal from '@/components/DuplicateActionModal';
import IconProgressState from '@/components/IconProgressState';
import LeverageRequestModal from '@/components/LeverageRequestModal';
import PersonActionPreview from '@/components/PersonActionPreview';
import PreferredButton from '@/components/PreferredButton';
import RPMDateTimePicker from '@/components/RPMDateTimePicker/RPMDateTimePicker';
import ScheduleButton from '@/components/ScheduleButton';
import ThreeDotsActionButton from '@/components/ThreeDotsActionButton';
import { ActionRelation, COMPLETE } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import { useSoundEffects } from '@/contexts/SoundEffects';
import { TOGGLE_BLOCK_COMPLETE } from '@/gql/block';
import { UpdateBlockResponse } from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useDeleteAction, useUpdateAction, useUpdateActionStatus } from '@/services/action/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { onMutateBlockStatus, useCompleteBlockStatus } from '@/services/myPlan/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { IconProject } from '@/theme/icons';
import { Action, ProgressStatus } from '@/types/actions';
import { DayWeek } from '@/types/calendar';
import { deleteActionFromPersonCache } from '@/utils/action';
import { formatDateToString, localDateToUTC, utcToLocalDate } from '@/utils/calendar';
import { generateLinearGradient } from '@/utils/color';
import rem from '@/utils/rem';
import {
  trackActionMovedDuringComplete,
  trackActionProgress,
  trackActionStarred,
  trackActionUnplannedDuringComplete,
} from '@/utils/tracking';
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { isNil, isUndefined } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';

export const ICON_PADDING = rem(2.5);

type ActionRowProp = {
  idx?: number;
  action: Action;
  readOnly?: boolean;
  showIndex?: boolean;
  progressStatusButtonDisabled?: boolean;
  hideButtons?: boolean;
  onClick?: () => void;
  onDeleteAction?: (action: Action) => void;
  onUpdateBlock?: (blockId: string) => void;
  onClickCreateBlock?: (actionId: string) => void;
  onClickRemoveFromBlock?: (actionId: string) => void;
  markBlockAsComplete?: boolean;
  withScheduleButton?: boolean;
  isCompleteMode?: boolean;
} & FlexProps;

function ActionRow({
  idx,
  action,
  showIndex = true,
  progressStatusButtonDisabled = false,
  hideButtons = false,
  onClick,
  onDeleteAction,
  onUpdateBlock,
  onClickCreateBlock,
  onClickRemoveFromBlock,
  markBlockAsComplete = false,
  withScheduleButton = false,
  isCompleteMode = false,
  readOnly = isCompleteMode,
  ...rest
}: ActionRowProp) {
  const toast = useToast();

  const {
    isOpen: isOpenDeleteActionModal,
    onOpen: openDeleteActionModal,
    onClose: onCloseDeleteActionModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDuplicateActionModal,
    onOpen: openDuplicateActionModal,
    onClose: onCloseDuplicateActionModal,
  } = useDisclosure();

  const {
    isOpen: isOpenLeverageRequestModal,
    onOpen: openLeverageRequestModal,
    onClose: onCloseLeverageRequestModal,
  } = useDisclosure();

  const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure();

  const isDailyPlanPage = useIsDailyPlanPage();

  const { playCompleteAudio } = useSoundEffects();

  const [isActiveScheduleButton, setIsActiveScheduleButton] = useState(false);

  // date picker information for the "move" button if isInComplete mode
  const [tabIndex, setTabIndex] = useState(isDailyPlanPage ? DAY : WEEK);
  const [dateRange, setDateRange] = useState(tabIndex === WEEK);
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState(new Date());
  const { data: datePickerWeeklyId, isLoading: datePickerWeeklyIdIsLoading } = useWeeklyPlanId(scheduledDate);

  const { data: todaysWeeklyPlanId } = useWeeklyPlanId(new Date());
  const isDarkMode = useIsDarkMode();

  const updateActionByPk = useUpdateAction({
    onSuccess: () => {
      if (isCompleteMode) {
        toast({
          title: 'Action updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  const updateActionStatus = useUpdateActionStatus();

  const completeBlockStatus = useCompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id, true),
  });

  const deleteAction = useDeleteAction({
    onSuccess: () => {
      if (isUndefined(action)) {
        return;
      }

      onDeleteAction && onDeleteAction(action);

      if (action.promises) {
        deleteActionFromPersonCache(action.id);

        queryClient.refetchQueries(keys.people.all.queryKey);
      }

      if (action?.blockId) {
        updateActionStatus.mutate({
          new: null,
          old: {
            id: action.id,
            block_id: action.blockId,
          },
        });
      }

      toast({
        title: 'Action deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseDeleteActionModal();
    },
  });

  const updateBlock = useMutation({
    mutationFn: () =>
      fetchData<UpdateBlockResponse>(TOGGLE_BLOCK_COMPLETE, {
        id: action?.blockId,
        isCompleted: false,
      }),

    onSuccess: () => {
      if (isUndefined(action)) {
        return;
      }

      onUpdateBlock && onUpdateBlock(String(action.blockId));
    },
  });

  // the schedule button shows "this week" or "today" and so ignores the context of the app
  // and should always take todays date or weeklyPlanId instead
  const onClickScheduleButton = useCallback(() => {
    if (!action?.id) return;

    // Used to create an immediate feedback while updating
    setIsActiveScheduleButton(true);

    updateActionByPk.mutate({
      actionId: action.id,
      set: {
        scheduledDate: isDailyPlanPage ? formatDateToString(new Date()) : null,
        weeklyPlanId: todaysWeeklyPlanId,
      },
    });
  }, [action?.id, updateActionByPk, todaysWeeklyPlanId, isDailyPlanPage]);

  const isActionScheduledSameDayWeek = useMemo(() => {
    if (isDailyPlanPage) {
      return action?.scheduledDate === formatDateToString(new Date());
    } else {
      return action?.weeklyPlanId === todaysWeeklyPlanId;
    }
  }, [action?.scheduledDate, action?.weeklyPlanId, todaysWeeklyPlanId, isDailyPlanPage]);

  const onCompleteBlock = useCallback(
    (blockId: string) => {
      completeBlockStatus.mutate({
        id: blockId,
      });
    },
    [completeBlockStatus],
  );

  const onClickProgressStatus = useCallback(
    (progressStatus: ProgressStatus) => {
      if (action?.block?.isCompleted && progressStatus !== 'complete') {
        updateBlock.mutate();
      }

      if (!action?.id) return;

      updateActionByPk.mutate({
        actionId: action.id,
        set: {
          progressStatus,
        },
      });

      if (progressStatus === 'complete') {
        playCompleteAudio();
      }

      if (markBlockAsComplete && progressStatus === 'complete' && action.blockId) {
        onCompleteBlock(action.blockId);
      }

      trackActionProgress(progressStatus, action);
    },

    [action, updateActionByPk, updateBlock, markBlockAsComplete, onCompleteBlock, playCompleteAudio],
  );

  const onClickPreferredButton = useCallback(() => {
    if (!action?.id) return;

    if (!action?.dateOfStarring) {
      // action is about to be starred in the below mutation
      trackActionStarred();
    }

    updateActionByPk.mutate({
      actionId: action.id,
      set: {
        dateOfStarring: typeof action?.dateOfStarring === 'string' ? null : new Date(),
      },
    });
  }, [action?.dateOfStarring, action?.id, updateActionByPk]);

  const onHandleDeleteAction = useCallback(() => {
    if (!action?.id) return;

    deleteAction.mutate({ actionId: action.id });
  }, [deleteAction, action?.id]);

  const handleClickCreateBlock = useCallback(() => {
    if (!action?.id || !onClickCreateBlock) {
      return undefined;
    }

    onClickCreateBlock(action.id);
  }, [action?.id, onClickCreateBlock]);

  const handleClickRemoveFromBlock = useCallback(() => {
    if (!action?.id || !onClickRemoveFromBlock) {
      return undefined;
    }

    onClickRemoveFromBlock(action.id);
  }, [action?.id, onClickRemoveFromBlock]);

  const onHandleUnplan = useCallback(
    (action: Action) => {
      updateActionByPk.mutate({
        actionId: action.id,
        set: {
          weeklyPlanId: null,
          scheduledDate: null,
          scheduledTime: null,
        },
      });

      isCompleteMode && trackActionUnplannedDuringComplete();
    },
    [updateActionByPk, isCompleteMode],
  );

  const onChangeTabs = useCallback((index: string | number) => {
    if ((index as DayWeek) === WEEK) {
      setScheduledTime(null);
    }

    setTabIndex(index as DayWeek);
    setDateRange((index as DayWeek) === WEEK);
  }, []);

  const handleScheduledDate = useCallback(
    (date: Date) => {
      if (scheduledTime) {
        date.setHours(scheduledTime.getHours());
        date.setMinutes(scheduledTime.getMinutes());

        setScheduledTime(date);
      }

      setScheduledDate(date);
    },
    [scheduledTime],
  );

  const handleScheduledTime = useCallback(
    (date: Date | null) => {
      if (!date) {
        setScheduledTime(null);
        return;
      }

      if (scheduledDate) {
        scheduledDate.setHours(date.getHours());
        scheduledDate.setMinutes(date.getMinutes());
      }

      setScheduledTime(scheduledDate);
    },
    [scheduledDate],
  );

  const actionNextUpdates = useCallback(
    (scheduledDate: string | null, scheduledTime: string | null) => {
      return isDailyPlanPage
        ? {
            scheduledDate: tabIndex === WEEK ? null : scheduledDate,
            scheduledTime: tabIndex === WEEK ? null : scheduledTime,
            weeklyPlanId: datePickerWeeklyId,
            timezone: scheduledTime ? Intl.DateTimeFormat().resolvedOptions().timeZone : '',
          }
        : {
            weeklyPlanId: datePickerWeeklyId,
            scheduledDate: tabIndex === WEEK ? null : scheduledDate,
            scheduledTime: null,
          };
    },
    [tabIndex, datePickerWeeklyId, isDailyPlanPage],
  );

  const onMoveToAnotherDay = useCallback(
    (action: Action, time?: Date | null) => {
      let innerTime: Date | string | null = time ?? null;

      onDatePickerClose();

      if (action.progressStatus === 'complete') return;

      if (isNil(time) && scheduledDate && action.scheduledTime) {
        const localDateTime = utcToLocalDate(formatDateToString(scheduledDate), action?.scheduledTime);
        innerTime = localDateToUTC(localDateTime)?.scheduledTime;
      }

      if (!isNil(time) && scheduledDate) {
        innerTime = localDateToUTC(time)?.scheduledTime;
      }

      const setAction = actionNextUpdates(
        scheduledDate ? formatDateToString(scheduledDate) : null,
        innerTime?.toString() ?? null,
      );

      updateActionByPk.mutate({
        actionId: action?.id,
        set: setAction,
      });

      isCompleteMode && trackActionMovedDuringComplete();
    },
    [actionNextUpdates, onDatePickerClose, updateActionByPk, scheduledDate, isCompleteMode],
  );

  const onHandleComplete = useCallback(
    (action: Action) => {
      updateActionByPk.mutate({
        actionId: action.id,
        set: {
          progressStatus: 'complete',
        },
      });

      if (markBlockAsComplete && action.blockId && onCompleteBlock) {
        onCompleteBlock(action.blockId);
      }
    },
    [updateActionByPk, onCompleteBlock, markBlockAsComplete],
  );

  return (
    <>
      <Flex
        sx={{
          '@media print': {
            border: `${rem(1)} solid var(--chakra-colors-print-stroke)`,
            overflow: 'hidden !important',
          },
        }}
        alignItems="center"
        gap={rem(10)}
        width="full"
        height={rem(32)}
        padding={`${rem(6)} ${rem(7)}`}
        background={action.dateOfStarring ? generateLinearGradient(action.category.color) : 'inherit'}
        opacity={action.progressStatus === COMPLETE ? '0.6' : '1'}
        borderRadius="4"
        cursor={readOnly && action.progressStatus === COMPLETE ? 'not-allowed' : 'inherit'}
        backgroundColor="background-tertiary"
        css={{
          touchAction: 'none',
        }}
        onClick={!readOnly ? onClick : undefined}
        {...rest}
      >
        <IconProgressState
          state={action.progressStatus}
          onClick={onClickProgressStatus}
          disabled={progressStatusButtonDisabled}
          tabIndex={readOnly ? -1 : 0}
          dimension={rem(14)}
        />

        {showIndex && (
          <Text as="span" textStyle="actionRowIndex">
            {idx}
          </Text>
        )}

        <EllipsisTooltipText flex={1} textStyle="actionRowTitle" color="text-primary" textAlign="left">
          {action.title}
        </EllipsisTooltipText>

        {!hideButtons && (
          <Flex alignItems="center" gap={rem(8)} cursor="default" onClick={(e) => e.stopPropagation()}>
            {!isCompleteMode && (
              <>
                {action?.project?.name && (
                  <Tooltip
                    aria-label={`Project: ${action.project.name}`}
                    label={`Project: ${action?.project?.name}`}
                    placement="top"
                  >
                    <Flex>
                      <Icon as={IconProject} width={rem(14)} height={rem(14)} />
                    </Flex>
                  </Tooltip>
                )}

                {action?.promises?.[0]?.kind === ActionRelation.Leverage && (
                  <>
                    <Box
                      onClick={
                        action.progressStatus === COMPLETE
                          ? undefined
                          : () => {
                              action?.promises?.[0]?.leverageRequestStatus ? openLeverageRequestModal() : null;
                            }
                      }
                    >
                      <PersonActionPreview promises={action?.promises} />
                    </Box>
                    <LeverageRequestModal
                      promise={action.promises[0]}
                      title={action.title}
                      isOpen={isOpenLeverageRequestModal}
                      onClose={onCloseLeverageRequestModal}
                    />
                  </>
                )}

                {action?.promises?.[0]?.kind === ActionRelation.Commit && (
                  <PersonActionPreview promises={action?.promises} />
                )}

                <Clock
                  duration={action.duration || '00:00:00'}
                  scheduledDate={action.scheduledDate}
                  scheduledTime={action.scheduledTime}
                  iconColor="text-primary"
                />
              </>
            )}

            {(!isCompleteMode || (isCompleteMode && !!action.dateOfStarring)) && (
              <PreferredButton
                isStarred={!!action.dateOfStarring}
                onClick={readOnly ? undefined : action.progressStatus === COMPLETE ? undefined : onClickPreferredButton}
                cursor={readOnly ? 'default' : 'pointer'}
                iconColor={isDarkMode ? 'white.500' : 'gray.500'}
                padding={ICON_PADDING}
                readOnly={readOnly}
              />
            )}

            {withScheduleButton && (
              <ScheduleButton
                onClick={!isActionScheduledSameDayWeek ? onClickScheduleButton : undefined}
                isActive={isActiveScheduleButton || isActionScheduledSameDayWeek}
                marginRight={ICON_PADDING}
              />
            )}

            {!readOnly && !isCompleteMode && (
              <ThreeDotsActionButton
                iconColor="text-primary"
                padding={ICON_PADDING}
                marginX={`-${ICON_PADDING}`}
                onDuplicateAction={action.scheduledDate ? openDuplicateActionModal : undefined}
                onEditAction={onClick}
                onRemoveActionItemClick={openDeleteActionModal}
                onCreateBlock={onClickCreateBlock ? handleClickCreateBlock : undefined}
                onRemoveFromBlock={onClickRemoveFromBlock ? handleClickRemoveFromBlock : undefined}
              />
            )}

            {isCompleteMode && (
              <>
                <Button onClick={() => onHandleUnplan(action)} variant="text">
                  Unplan
                </Button>

                <Popover
                  isOpen={isDatePickerOpen}
                  onClose={onDatePickerClose}
                  onOpen={onDatePickerOpen}
                  placement="right"
                >
                  <PopoverTrigger>
                    <Button aria-label="Select a date range" variant="text">
                      Move
                    </Button>
                  </PopoverTrigger>

                  {/* !TODO find a better approach, regarding fixing the z-index of the popover
                  inside the portal. we need the portal to make sure the popover is shown over app content
                  but the popover and modal zindex conflict with each other so inside a modal it displays incorrectly
                  UNDER the modal... there is alternative solutions that affect the popover globally
                  but at this point it's too much of a risk before production deploy 
                  https://github.com/chakra-ui/chakra-ui/issues/6794
                  */}
                  <Portal>
                    <Box
                      sx={{
                        '& .chakra-popover__popper': {
                          zIndex: 'popover',
                        },
                      }}
                    >
                      <PopoverContent
                        width={{ lg: rem(416) }}
                        padding={{ base: rem(8), lg: rem(16) }}
                        border={`${rem(1)} solid`}
                        borderColor="stroke-primary"
                        borderRadius={rem(4)}
                        backgroundColor="background-primary"
                      >
                        <PopoverBody className={tabIndex === WEEK ? 'week-style' : 'day-style'} padding={0}>
                          <RPMDateTimePicker
                            dateRange={dateRange}
                            onChangeTabs={onChangeTabs}
                            onSave={() => onMoveToAnotherDay(action)}
                            onCancel={onDatePickerClose}
                            scheduledTime={scheduledTime}
                            selectedDate={scheduledDate ?? new Date()}
                            tabIndex={tabIndex}
                            updateScheduledTime={handleScheduledTime}
                            updateSelectedDate={handleScheduledDate}
                            withTimePicker={tabIndex === DAY}
                            isLoading={!datePickerWeeklyId || datePickerWeeklyIdIsLoading}
                            showDayWeekSelector={false}
                          />
                        </PopoverBody>
                      </PopoverContent>
                    </Box>
                  </Portal>
                </Popover>

                <Button
                  borderColor="var(--chakra-colors-green-400)"
                  onClick={() => onHandleComplete(action)}
                  variant="text"
                >
                  Complete
                </Button>
              </>
            )}
          </Flex>
        )}
        {hideButtons && !!action.dateOfStarring && (
          <PreferredButton
            isStarred={!!action.dateOfStarring}
            iconColor={action.dateOfStarring ? action.category.color : 'text-primary'}
            cursor="default"
            padding={ICON_PADDING}
            readOnly={true}
          />
        )}
      </Flex>

      {isOpenDeleteActionModal && (
        <DeleteActionModal
          isOpen={isOpenDeleteActionModal}
          onCloseDeleteActionModal={onCloseDeleteActionModal}
          onDeleteAction={onHandleDeleteAction}
        />
      )}

      {isOpenDuplicateActionModal && (
        <DuplicateActionModal
          action={action}
          isOpen={isOpenDuplicateActionModal}
          onCloseDuplicateActionModal={onCloseDuplicateActionModal}
        />
      )}
    </>
  );
}

export default memo(ActionRow);
