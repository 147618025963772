import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { IconArrowDownFull, IconCalendar } from '@/theme/icons';
import { endOfTheWeek, startOfTheWeek } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, HStack, Text, useBreakpointValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { RefObject, forwardRef } from 'react';

type Props = {
  selectedDate: Date;
  showCalendarIcon?: boolean;
  showDatePicker?: boolean;
  onDatePickerOpen?: () => void;
  borderColor?: string;
};

const InnerDate = forwardRef(
  (
    {
      selectedDate,
      showCalendarIcon = false,
      showDatePicker = false,
      onDatePickerOpen,
      borderColor = 'stroke-primary',
    }: Props,
    ref,
  ) => {
    const startDate = selectedDate && format(startOfTheWeek(selectedDate), 'MMM dd');
    const endDate = selectedDate && format(endOfTheWeek(selectedDate), 'MMM dd');
    const endDateYear = selectedDate && format(endOfTheWeek(selectedDate), 'yyyy');
    const isDailyPlanPage = useIsDailyPlanPage();
    const isMobileScreen = useBreakpointValue({ base: true, lg: false });

    const dateLabel = isDailyPlanPage
      ? format(selectedDate, 'EEE, MMM dd, yyyy')
      : `${startDate} - ${endDate}` + `${!isMobileScreen ? `, ${endDateYear}` : ``}`;

    if (showDatePicker) {
      return (
        <Button
          ref={ref as RefObject<HTMLButtonElement>}
          justifyContent="center"
          gap={rem(8)}
          width={{ lg: rem(256) }}
          height="full"
          minHeight={rem(32)}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor={borderColor}
          borderRadius={rem(4)}
          backgroundColor="background-primary"
          onClick={() => onDatePickerOpen && onDatePickerOpen()}
          paddingX={rem(8)}
          variant="ghost"
        >
          {showCalendarIcon && <Box as={IconCalendar} />}
          <Text as="span" textStyle={{ base: 'mediumBlack', lg: 'largeBlack' }}>
            {dateLabel}
          </Text>

          <Box as={IconArrowDownFull} />
        </Button>
      );
    }

    return (
      <HStack
        textStyle={{ base: 'mediumBlack', lg: 'largeBlack' }}
        justifyContent="center"
        gap={rem(8)}
        width={{ lg: rem(240) }}
        height="full"
        minHeight={rem(32)}
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius={rem(4)}
        backgroundColor="background-primary"
        paddingX={rem(8)}
      >
        {showCalendarIcon && <Box as={IconCalendar} />}
        <Text as="span">{dateLabel}</Text>
      </HStack>
    );
  },
);
export default InnerDate;
