import { PeopleProvider } from '@/contexts/PeopleCtx';
import DashboardLayout from '@/layouts/Dashboard';
import { useLeveragedAndCommittedActions } from '@/services/action/hooks';
import { useCategories } from '@/services/categories/hooks';
import rem from '@/utils/rem';
import { Flex, Spinner } from '@chakra-ui/react';
import { memo } from 'react';

import People from './people';

function PeopleWrapper() {
  const { isLoading: isActionsLoading } = useLeveragedAndCommittedActions();
  const { isLoading: isCategoriesLoading } = useCategories();

  return (
    <PeopleProvider>
      <DashboardLayout pageTitle="People" paddingInline="0" paddingTop="0">
        {isActionsLoading || isCategoriesLoading ? (
          <Flex alignItems="center" justifyContent="center" marginTop={rem(128)}>
            <Spinner size="xl" />
          </Flex>
        ) : (
          <People />
        )}
      </DashboardLayout>
    </PeopleProvider>
  );
}

export default memo(PeopleWrapper);
