import { IconArrowDownFull, IconCalendar } from '@/theme/icons';
import { endOfTheWeek, startOfTheMonth } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { RefObject, forwardRef } from 'react';

type Props = {
  selectedDate: Date;
  showCalendarIcon?: boolean;
  showDatePicker?: boolean;
  onDatePickerOpen?: () => void;
};

const InnerDate = forwardRef(
  ({ selectedDate, showCalendarIcon = false, showDatePicker = false, onDatePickerOpen }: Props, ref) => {
    const currentMonth = selectedDate && format(startOfTheMonth(selectedDate), 'MMMM');
    const endDateYear = selectedDate && format(endOfTheWeek(selectedDate), 'yyyy');

    if (showDatePicker) {
      return (
        <Button
          ref={ref as RefObject<HTMLButtonElement>}
          justifyContent="center"
          gap={rem(8)}
          height="full"
          minHeight={rem(28)}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="stroke-primary"
          borderRadius={rem(4)}
          backgroundColor="background-primary"
          onClick={() => onDatePickerOpen && onDatePickerOpen()}
          paddingX={rem(12)}
          paddingY={rem(3)}
          variant="ghost"
        >
          {showCalendarIcon && <Box as={IconCalendar} />}
          <Text as="span" textStyle="smallBlack" textTransform="uppercase">
            {`${currentMonth}, ${endDateYear}`}
          </Text>

          <Box as={IconArrowDownFull} />
        </Button>
      );
    }

    return (
      <HStack
        textStyle="largeBlack"
        justifyContent="center"
        gap={rem(8)}
        width={rem(208)}
        height="full"
        minHeight={rem(32)}
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="stroke-primary"
        borderRadius={rem(4)}
        backgroundColor="background-primary"
        paddingX={rem(12)}
        paddingY={rem(8)}
      >
        {showCalendarIcon && <Box as={IconCalendar} />}
        <Text
          as="span"
          fontFamily="Barlow Condensed"
          fontSize={rem(24)}
          fontWeight={900}
          textTransform="uppercase"
        >{`${currentMonth}, ${endDateYear}`}</Text>
      </HStack>
    );
  },
);
export default InnerDate;
