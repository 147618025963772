import rem from '@/utils/rem';
import { Box, Flex, FlexProps, Text, TextProps, VStack } from '@chakra-ui/react';
import { FunctionComponent, ReactNode, SVGProps } from 'react';

type EmptyActionListProps = {
  withIcons?: boolean;
  motivational?: boolean | 'small';
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
  titleStyle?: TextProps;
  message?: string;
  messageStyle?: TextProps;
  iconStyle?: FlexProps;
  cta?: ReactNode;
};

function EmptyActionList({
  withIcons = false,
  motivational = false,
  icon,
  title,
  titleStyle,
  message,
  messageStyle,
  iconStyle,
  cta,
  ...rest
}: EmptyActionListProps & FlexProps) {
  if (withIcons) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={rem(16)}
        height="full"
        borderRadius={rem(8)}
        backgroundColor="background-tertiary"
        paddingInline={{ base: rem(32), md: rem(80), xl: rem(160) }}
        paddingY={rem(32)}
        {...rest}
      >
        {icon && (
          <Flex
            alignItems="center"
            justifyContent="center"
            width={rem(40)}
            height={rem(40)}
            borderRadius={99}
            backgroundColor="background-primary"
            {...iconStyle}
          >
            <Box as={icon} width={rem(24)} height={rem(24)} color="text-primary" />
          </Flex>
        )}

        <VStack>
          {title && (
            <Text
              textStyle="smallBlack"
              marginBottom={rem(4)}
              color="text-tertiary"
              textAlign="center"
              textTransform="uppercase"
              {...titleStyle}
            >
              {title}
            </Text>
          )}
          {message && (
            <Text textStyle="medium" color="text-primary" textAlign="center" {...messageStyle}>
              {message}
            </Text>
          )}
        </VStack>

        {cta && cta}
      </Flex>
    );
  }

  if (motivational) {
    return (
      <Flex
        alignItems={motivational === 'small' ? 'flex-start' : 'center'}
        justifyContent="center"
        flexDirection="column"
        gap={rem(8)}
        width="full"
        height="full"
        padding={rem(16)}
        borderRadius={motivational === 'small' ? rem(4) : rem(8)}
        backgroundColor="background-tertiary"
        {...rest}
      >
        {title && (
          <Text
            textStyle={motivational === 'small' ? 'xsmall' : 'small'}
            color={motivational === 'small' ? 'text-primary' : 'text-tertiary'}
            fontWeight={motivational === 'small' ? '600' : '900'}
            textAlign="center"
            textTransform="uppercase"
            {...titleStyle}
          >
            {title}
          </Text>
        )}
        {message && (
          <Text
            textStyle={motivational === 'small' ? 'xsmall' : 'medium'}
            color="text-primary"
            textAlign="center"
            {...messageStyle}
          >
            {message}
          </Text>
        )}
      </Flex>
    );
  }

  if (cta) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width="80%"
        maxWidth={rem(376)}
        height="full"
        textAlign="center"
        {...rest}
      >
        {title && (
          <Text
            textStyle="smallBlack"
            marginBottom={rem(12)}
            color="text-tertiary"
            textAlign="center"
            textTransform="uppercase"
            {...titleStyle}
          >
            {title}
          </Text>
        )}

        {message && (
          <Text as="p" textStyle="medium" marginBottom={rem(24)} color="text-primary" {...messageStyle}>
            {message}
          </Text>
        )}

        {cta && cta}
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" justifyContent="center" height="full" {...rest}>
      <Text textStyle="xsmall" color="text-primary" textTransform="uppercase" opacity="0.5" {...messageStyle}>
        {message}
      </Text>
    </Flex>
  );
}

export default EmptyActionList;
