import { CATEGORIES_ICONS } from '@/constants/category';
import { IconHourglass, IconStarFull } from '@/theme/icons';
import { Category } from '@/types/category';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName, getSecondsFromDuration, humanDuration, isUncategorized } from '@/utils/index';
import rem from '@/utils/rem';
import { Flex, HStack, Icon, InteractivityProps, Text, Tooltip, VStack, useColorMode } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { ReactNode } from 'react';

type Props = {
  category?: Category | null;
  stars?: string;
  duration?: string;
  showDurationTimers?: boolean;
  draggable?: boolean;
  accordionButton?: JSX.Element;
  threeDotsButton?: ReactNode;
};

function CategoryHeader({
  category = null,
  stars = '00:00',
  duration = '00:00',
  showDurationTimers = true,
  draggable,
  accordionButton,
  threeDotsButton,
}: Props) {
  const { colorMode } = useColorMode();
  const isDarkTheme = colorMode === 'dark';

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: category?.id ?? Date.now(),
    data: { category, containerId: 'categoryHeader' },
    disabled: !draggable,
  });

  // "attributes" and "listeners" props always convert the element to a button,
  // even when disabled:true is passed to useDraggable. For this reason, we
  // pass attributes and listeners only when draggable is true.
  const draggableProps = draggable
    ? {
        ...attributes,
        ...listeners,
        cursor: 'grab' satisfies InteractivityProps['cursor'],
      }
    : {};

  const durationStarred = humanDuration(getSecondsFromDuration(stars));
  const durationTotal = humanDuration(getSecondsFromDuration(duration));

  const categoryRole =
    category && isUncategorized(category) ? 'Capture your actions, outcomes, and results' : category?.role;

  const categoryName = category ? fixUncategorizedName(category.name) : undefined;

  return (
    <VStack alignItems="flex-start" justifyContent="flex-start" spacing={0}>
      <HStack justifyContent="space-between" width="full" paddingBottom={rem(7)}>
        <Flex ref={setNodeRef} alignItems="start" {...draggableProps}>
          <Flex alignItems="center" justifyContent="center">
            <Flex
              alignItems="center"
              justifyContent="center"
              width={rem(24)}
              height={rem(24)}
              marginRight={rem(4)}
              borderRadius="full"
              aspectRatio={1}
            >
              <Icon
                as={CATEGORIES_ICONS?.[category?.icon ?? 'uncategorized']}
                width={rem(16)}
                height={rem(16)}
                color={category?.color}
              />
            </Flex>
            <Tooltip label={categoryName} placement="top">
              <Text
                sx={{
                  '@media print': {
                    color: 'print-text',
                  },
                }}
                textStyle="h5BarSB"
                overflow="hidden"
                marginTop={rem(-3)}
                paddingRight={rem(12)}
                color={getColorFromToken(category?.color, undefined, isDarkTheme ? '100' : '800')}
                textTransform="uppercase"
                noOfLines={1}
              >
                {categoryName}
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        {(!!threeDotsButton || !!accordionButton) && (
          <HStack gap={0}>
            {threeDotsButton}
            {accordionButton}
          </HStack>
        )}
      </HStack>
      <HStack width="full" paddingLeft={rem(26)}>
        {categoryRole && (
          <Tooltip label={categoryRole} placement="top">
            <Text
              sx={{
                '@media print': {
                  color: 'print-text',
                },
              }}
              textStyle="xsmall"
              color={getColorFromToken(category?.color, undefined, isDarkTheme ? '100' : '800')}
              textTransform="uppercase"
              noOfLines={1}
            >
              {categoryRole}
            </Text>
          </Tooltip>
        )}
        <HStack alignItems="center" gap={rem(12)}>
          {showDurationTimers && (
            <Flex gap={rem(8)}>
              <Tooltip label="Time remaining to complete all of your starred actions" placement="top">
                <Flex alignItems="center" gap={rem(4)}>
                  <Icon as={IconStarFull} width={rem(12)} height={rem(12)} color="gray.300" />
                  <Text textStyle="xsmall" color="text-primary" whiteSpace="nowrap">
                    {durationStarred || '0h'}
                  </Text>
                </Flex>
              </Tooltip>
              <Tooltip label="Time remaining to complete all of your actions" placement="top">
                <Flex alignItems="center" gap={rem(4)}>
                  <Icon as={IconHourglass} width={rem(12)} height={rem(12)} color="gray.300" />
                  <Text textStyle="xsmall" color="text-primary" whiteSpace="nowrap">
                    {durationTotal || '0h'}
                  </Text>
                </Flex>
              </Tooltip>
            </Flex>
          )}
        </HStack>
      </HStack>
    </VStack>
  );
}

export default CategoryHeader;
