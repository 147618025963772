import ScheduledTime, { ScheduledTimeProps } from '@/components/ActionModal/ScheduledTime';
import DynamicSegmentedButton from '@/components/DynamicSegmentedButton';
import { DAILY_WEEKLY_VIEW_SEGMENTED, DAY, WEEK, months } from '@/constants/calendar';
import { endOfTheWeek, startOfTheWeek } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { getMonth, getYear, isEqual } from 'date-fns';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

export type DateTimePickerProps = {
  children?: ReactNode;
  dateRange?: boolean;
  selectedDate: Date;
  tabIndex?: string;
  onChangeTabs?: (index: string | number) => void;
  updateSelectedDate: (selectedDate: Date) => void;
  onSave?: (time?: Date | null) => void;
  onCancel?: () => void;
  withTimePicker?: boolean;
  withActionButtons?: boolean;
  isLoading?: boolean;
  showDayWeekSelector?: boolean;
  datePickerProps?: ReactDatePickerProps;
} & Partial<ScheduledTimeProps>;

const DateTimePicker = ({
  dateRange = false,
  onChangeTabs,
  scheduledTime,
  selectedDate,
  tabIndex,
  updateScheduledTime,
  updateSelectedDate,
  withTimePicker = false,
  withActionButtons = true,
  onSave,
  onCancel,
  isLoading = false,
  showDayWeekSelector = true,
  datePickerProps,
}: DateTimePickerProps) => {
  const weekStartDay = selectedDate && startOfTheWeek(selectedDate);
  const weekEndDay = selectedDate && endOfTheWeek(selectedDate);
  const [startDate, setStartDate] = useState<Date | undefined>(selectedDate);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const [tempScheduledTime, setTempScheduledTime] = useState<Date | null>(
    withTimePicker && scheduledTime ? scheduledTime : null,
  );

  const onChange = useCallback(
    (dates: [Date, Date]) => {
      if (dateRange) {
        const [start] = dates;
        const weekStartDay = startOfTheWeek(start);
        const weekEndDay = endOfTheWeek(start);

        setStartDate(weekStartDay);
        setEndDate(weekEndDay);
        updateSelectedDate(start);
      } else {
        setStartDate(dates as unknown as Date);
        setEndDate(dates as unknown as Date);
        updateSelectedDate(dates as unknown as Date);
      }
    },
    [dateRange, updateSelectedDate],
  );

  const onChangeTime = (date: Date | null) => {
    setTempScheduledTime(date);
  };

  const handleChangeTabs = (val: string | number) => {
    let newVal: string | undefined = undefined;

    if (typeof val === 'number') {
      newVal = val === 0 ? DAY : WEEK;
    } else {
      newVal = val;
    }

    onChangeTabs && onChangeTabs(newVal);
  };

  const handleSubmit = useCallback(() => {
    let time = null;

    if (tempScheduledTime && !isEqual(selectedDate, tempScheduledTime)) {
      time = tempScheduledTime;
    }

    updateScheduledTime && updateScheduledTime(time);

    onSave && onSave(time);
  }, [onSave, selectedDate, tempScheduledTime, updateScheduledTime]);

  useEffect(() => {
    if (dateRange) {
      setStartDate(weekStartDay);
      setEndDate(weekEndDay);
    } else {
      setStartDate(selectedDate);
      setEndDate(selectedDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dateRange]);

  useEffect(() => {
    if (scheduledTime == null) {
      return;
    }

    setTempScheduledTime(scheduledTime);

    return () => setTempScheduledTime(null);
  }, [scheduledTime]);

  useEffect(() => {
    if (withTimePicker) {
      return;
    }

    setTempScheduledTime(null);
  }, [withTimePicker]);

  return (
    <Flex flexDirection="column">
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange={dateRange}
        inline
        useWeekdaysShort
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        calendarStartDay={1}
        // TODO: when you change months, react-datepicker keeps highlighted the
        // day that is the starting point for keyboard navigation, and that
        // unexpected highlighted day confuses users; you can test it on
        // https://reactdatepicker.com. There's no easy fix to this.
        // Maybe it can be hacked from the outside, but it would require a lot
        // of time and it would lead to a fragile solution. Since also the
        // surrounding components are not accessible, for now we disable the
        // keyboard navigation. Once we rework accessibility, we can check if a
        // newer version of this library (or a complete different one) can offer
        // a better solution.
        disabledKeyboardNavigation
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <HStack justifyContent="space-between">
            <Flex>
              <IconButton
                width="auto"
                minWidth="auto"
                fontSize={rem(25)}
                aria-label="previous month"
                icon={<Box as={MdOutlineKeyboardArrowLeft} color="text-primary" />}
                isDisabled={prevMonthButtonDisabled}
                onClick={decreaseMonth}
                variant="unstyled"
              />

              <Flex alignItems="center" marginInline={rem(13)}>
                <Text as="span" color="text-primary" fontSize={rem(14)} fontWeight={900} lineHeight={rem(18)}>
                  {`${months[getMonth(date)]?.substring(0, 3)} ${getYear(date)}`}
                </Text>
              </Flex>

              <IconButton
                width="auto"
                minWidth="auto"
                fontSize={rem(25)}
                aria-label="next month"
                icon={<Box as={MdOutlineKeyboardArrowRight} color="text-primary" />}
                isDisabled={nextMonthButtonDisabled}
                onClick={increaseMonth}
                variant="unstyled"
              />
            </Flex>

            <Flex height="full">
              {!showDayWeekSelector ||
                (onChangeTabs && tabIndex && (
                  <DynamicSegmentedButton
                    onChange={handleChangeTabs}
                    options={DAILY_WEEKLY_VIEW_SEGMENTED}
                    layoutId="datepicker-day-week-selector"
                    startIndex={tabIndex === DAY ? 0 : 1}
                    marginTop={rem(3)}
                    height={rem(42)}
                    innerHeight={rem(36)}
                  />
                ))}
            </Flex>
          </HStack>
        )}
        dayClassName={() => (dateRange ? 'react-datepicker__day--has-range' : null)}
        {...datePickerProps}
      />
      {(withTimePicker || withActionButtons) && (
        <Flex gap={rem(16)} paddingTop={rem(16)}>
          {withTimePicker && (
            <ScheduledTime
              scheduledTime={tempScheduledTime}
              updateScheduledTime={onChangeTime}
              selectedDate={selectedDate}
              padding={rem(16)}
              color="text-primary"
              size="lg"
              variant="secondary"
            />
          )}
          {withActionButtons && (
            <>
              <Button marginLeft="auto" padding={rem(16)} onClick={onCancel} size="lg" variant="secondary">
                Cancel
              </Button>
              <Button padding={rem(16)} isLoading={isLoading} onClick={handleSubmit} size="lg" variant="primary">
                Save
              </Button>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default DateTimePicker;
