import { IconCalendar, IconClock, IconHourglass } from '@/theme/icons';
import { formatStringToDate, utcToLocalDate } from '@/utils/calendar';
import { convertToMinutes } from '@/utils/events';
import { humanDuration } from '@/utils/index';
import rem from '@/utils/rem';
import { Flex, Icon, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';

function isScheduled(scheduledDate: string | null, scheduledTime: string | null) {
  return scheduledDate !== null && scheduledTime !== null;
}

type ClockProps = {
  iconColor?: string;
  scheduledDate: string | null;
  scheduledTime: string | null;
  duration: string;
};

function Clock({ iconColor = 'white.500', scheduledDate, scheduledTime, duration }: ClockProps) {
  if (isScheduled(scheduledDate, scheduledTime) && scheduledDate) {
    const label = `Scheduled: ${format(
      utcToLocalDate(scheduledDate, scheduledTime),
      "MMMM dd',' yyyy '/' h:mma",
    )} / ${humanDuration(convertToMinutes(duration) * 60)}`;

    return (
      <Tooltip aria-label={label} label={label} placement="top">
        <Flex>
          <Icon as={IconClock} width={rem(14)} height={rem(14)} color={iconColor} />
        </Flex>
      </Tooltip>
    );
  }

  if (scheduledDate !== null) {
    const label = `Planned: ${format(formatStringToDate(scheduledDate), "MMMM dd',' yyyy")} / ${humanDuration(
      convertToMinutes(duration) * 60,
    )}`;

    return (
      <Tooltip aria-label={label} label={label} placement="top">
        <Flex>
          <Icon as={IconCalendar} width={rem(14)} height={rem(14)} color={iconColor} />
        </Flex>
      </Tooltip>
    );
  }

  const label = `Duration: ${humanDuration(convertToMinutes(duration) * 60)}`;

  return (
    <Tooltip aria-label={label} label={label} placement="top">
      <Flex>
        <Icon as={IconHourglass} width={rem(14)} height={rem(14)} color={iconColor} />
      </Flex>
    </Tooltip>
  );
}

export default Clock;
