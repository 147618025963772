import Input, { InputProps } from '@/components/Input';
import { cleanCategoryForMutation } from '@/services/categories';
import { useUpdateCategoryMutation } from '@/services/categories/hooks';
import { IconCheckmarkSingle, IconClose, IconTrash } from '@/theme/icons';
import { Category } from '@/types/category';
import { updateCategoryInCache } from '@/utils/category';
import { getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { Box, Flex, IconButton, Text, VStack, VisuallyHidden, useToast } from '@chakra-ui/react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ComboBox,
  Button as ComboBoxButton,
  Input as ComboBoxInput,
  Header,
  Label,
  ListBox,
  ListBoxItem,
  Popover,
  Section,
} from 'react-aria-components';

export type PurposeSelectItem = { id: number; text: string; isUltimate?: boolean };

export const PURPOSE_SELECT_BEHAVIOR_BLOCK = 'BLOCK';
export const PURPOSE_SELECT_BEHAVIOR_CATEGORY = 'CATEGORY';

type PurposeSelectProps = InputProps & {
  behavior?: typeof PURPOSE_SELECT_BEHAVIOR_BLOCK | typeof PURPOSE_SELECT_BEHAVIOR_CATEGORY;
  category?: Category;
  hideLabel?: boolean;
  onChange: (value: string) => void;
  onUpdateCategory?: (updatedCategory: Category) => void;
};

type SelectItemProps = PurposeSelectItem & {
  category: PurposeSelectProps['category'];
  onUpdateCategory?: PurposeSelectProps['onUpdateCategory'];
  isMenuOpen: boolean;
};

const SelectItem = ({ category, text, isUltimate, onUpdateCategory, isMenuOpen }: SelectItemProps) => {
  const updateCategory = useUpdateCategoryMutation();
  const toast = useToast();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onDeletePurpose = useCallback(
    (purposeToDelete: string) => {
      if (!category) {
        return;
      }

      const filteredSecondaryPurposes =
        category.secondaryPurposes?.filter((item) => item !== null && item !== undefined) ?? [];

      const updatedPurposesArray = filteredSecondaryPurposes.filter((purpose) => purpose !== purposeToDelete);

      updateCategory.mutate(
        {
          ...cleanCategoryForMutation(category),
          secondaryPurposes: [...updatedPurposesArray],
        },
        {
          onSuccess: (_, variables) => {
            updateCategoryInCache(variables);
            onUpdateCategory && onUpdateCategory(variables);

            toast({
              title: 'Purpose deleted',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },

          onError: (error) => {
            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [category, updateCategory, toast, onUpdateCategory],
  );

  useEffect(() => {
    if (showDeleteConfirmation && !isMenuOpen) {
      setShowDeleteConfirmation(false);
    }
  }, [isMenuOpen, showDeleteConfirmation, setShowDeleteConfirmation]);

  return (
    <Flex
      as={ListBoxItem}
      position="relative"
      alignItems="center"
      width={!isUltimate ? 'calc(100% - 150px)' : '100%'}
      padding={`${rem(10)} ${rem(16)}`}
      _hover={{
        backgroundColor: 'background-secondary',
        '.deleteMessage': {
          backgroundColor: 'background-secondary',
        },
      }}
      _focusVisible={{
        backgroundColor: 'background-secondary',
        '.deleteMessage': {
          backgroundColor: 'background-secondary',
        },
      }}
      textValue={text}
    >
      <Text as="span" textStyle="medium" _hover={{ cursor: 'pointer' }} noOfLines={1}>
        {text}
      </Text>
      {isUltimate && (
        <Text
          as="span"
          textStyle="caption"
          marginLeft={rem(8)}
          color="background-primary"
          borderRadius={rem(4)}
          backgroundColor={getColorFromToken(category?.color)}
          paddingX={rem(8)}
          paddingY={rem(4)}
        >
          Ultimate
        </Text>
      )}

      {!isUltimate && (
        <Flex
          className="deleteMessage"
          position="absolute"
          top={0}
          right={rem(-150)}
          bottom={0}
          alignItems="center"
          justifyContent="flex-end"
          gap={rem(8)}
          width={rem(150)}
        >
          {showDeleteConfirmation ? (
            <>
              {!updateCategory.isLoading && (
                <>
                  <Text as="span" textStyle="small" color="text-primary">
                    Are you sure?
                  </Text>
                  <IconButton
                    minWidth={rem(14)}
                    height="100%"
                    aria-label="No"
                    icon={<IconClose height={rem(14)} width={rem(14)} />}
                    isDisabled={updateCategory.isLoading}
                    isLoading={updateCategory.isLoading}
                    onClick={() => setShowDeleteConfirmation(false)}
                    variant="tertiary"
                  />
                </>
              )}
              <IconButton
                minWidth={rem(14)}
                height="100%"
                marginRight={rem(22)}
                aria-label="Yes"
                icon={<IconCheckmarkSingle height={rem(14)} width={rem(14)} />}
                isDisabled={updateCategory.isLoading}
                isLoading={updateCategory.isLoading}
                onClick={() => onDeletePurpose(text)}
                variant="tertiary"
              />
            </>
          ) : (
            <IconButton
              marginRight={rem(8)}
              aria-label="Delete"
              icon={<IconTrash />}
              isDisabled={updateCategory.isLoading}
              isLoading={updateCategory.isLoading}
              onClick={() => setShowDeleteConfirmation(true)}
              variant="tertiary"
            />
          )}
        </Flex>
      )}
    </Flex>
  );
};

const PurposeSelect = forwardRef<HTMLInputElement, PurposeSelectProps>(
  (
    {
      behavior = PURPOSE_SELECT_BEHAVIOR_BLOCK,
      category,
      hideLabel = false,
      label = 'Purpose',
      value,
      onChange,
      onUpdateCategory,
      ...props
    },
    ref,
  ) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toast = useToast();

    const updateCategory = useUpdateCategoryMutation();

    const purposes: PurposeSelectItem[] = useMemo(() => {
      const purposes: PurposeSelectItem[] = [];
      if (category?.ultimatePurpose) {
        purposes.push({ text: category?.ultimatePurpose, isUltimate: true, id: 1 });
      }
      return purposes
        .concat(
          category?.secondaryPurposes?.map((purpose, index) => ({
            id: index + 2,
            text: purpose,
          })) || [],
        )
        .filter((purpose) => purpose.text !== '' && purpose.text !== undefined);
    }, [category]);

    const setUltimatePurposeForCategory = useCallback(
      (purpose: string) => {
        if (!category) {
          return;
        }
        const filteredSecondaryPurposes =
          category?.secondaryPurposes?.filter((item) => item !== null && item !== undefined) ?? [];

        // if empty string => delete current ultimate purpose from the category
        // if new value => make current ultimate purpose secondary, create new ultimate purpose for the category
        // if one of the values in the list => make the current ultimate purpose secondary, and selected value the new ultimate
        let nextCategory = null;
        const previousUltimatePurpose = category.ultimatePurpose;
        if (purpose.trim() === '') {
          nextCategory = {
            ...cleanCategoryForMutation(category),
            ultimatePurpose: '',
          };
        } else if (!purposes.find((p) => p.text.trim().toLowerCase() === purpose.trim().toLowerCase())) {
          nextCategory = {
            ...cleanCategoryForMutation(category),
            ultimatePurpose: purpose,
          };
          if (previousUltimatePurpose) {
            nextCategory.secondaryPurposes = [
              ...filteredSecondaryPurposes.filter((p) => p !== previousUltimatePurpose),
              previousUltimatePurpose,
            ];
          }
        } else if (purpose !== category.ultimatePurpose) {
          nextCategory = {
            ...cleanCategoryForMutation(category),
            ultimatePurpose: purpose,
          };
          nextCategory.secondaryPurposes = [...filteredSecondaryPurposes.filter((p) => p !== purpose)];
          if (previousUltimatePurpose) {
            nextCategory.secondaryPurposes.push(previousUltimatePurpose);
          }
        }
        if (nextCategory) {
          updateCategory.mutate(nextCategory, {
            onSuccess: async (_, variables) => {
              updateCategoryInCache(variables);
              onUpdateCategory && onUpdateCategory(variables);

              toast({
                title: 'Purpose updated',
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
            },
          });
        }
      },
      [category, purposes, updateCategory, onUpdateCategory, toast],
    );

    const createPurpose = useCallback(
      (purpose: string) => {
        if (behavior === PURPOSE_SELECT_BEHAVIOR_CATEGORY) {
          return setUltimatePurposeForCategory(purpose);
        }

        if (
          purpose.trim() !== '' &&
          !purposes.find((p) => p.text.trim().toLowerCase() === purpose.trim().toLowerCase()) &&
          category
        ) {
          const filteredSecondaryPurposes =
            category?.secondaryPurposes?.filter((item) => item !== null && item !== undefined) ?? [];

          updateCategory.mutate(
            {
              ...cleanCategoryForMutation(category),
              secondaryPurposes: [...filteredSecondaryPurposes, purpose],
            },
            {
              onSuccess: async (_, variables) => {
                updateCategoryInCache(variables);
                onUpdateCategory && onUpdateCategory(variables);

                toast({
                  title: 'Purpose created',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              },
            },
          );
        }
      },
      [behavior, category, purposes, setUltimatePurposeForCategory, updateCategory, onUpdateCategory, toast],
    );

    return (
      <ComboBox
        menuTrigger="focus"
        aria-label={hideLabel ? label : undefined}
        allowsCustomValue
        inputValue={value}
        onInputChange={onChange}
        onOpenChange={setIsMenuOpen}
        onBlur={(e) => createPurpose(e.target.value)}
      >
        {!hideLabel && (
          <Text as={Label} textStyle="caption" color="text-tertiary">
            {label}
          </Text>
        )}
        <Box marginTop={rem(16)}>
          <Input
            as={ComboBoxInput}
            ref={ref}
            width="100%"
            type="text"
            autoComplete="off"
            _placeholder={{
              color: 'text-tertiary',
              opacity: 0.3,
              fontSize: '3xl',
              fontWeight: 500,
            }}
            fontSize="3xl"
            fontWeight={500}
            paddingBottom={rem(16)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') createPurpose(e.currentTarget.value);
            }}
            {...props}
          />
          {/* react-aria-components Button needed for combobox to open/close as expected */}
          <VisuallyHidden>
            <ComboBoxButton />
          </VisuallyHidden>
        </Box>
        {purposes.length > 0 ? (
          <Box
            as={Popover}
            width="var(--trigger-width)"
            border={`${rem(1)} solid`}
            borderColor="stroke-primary"
            backgroundColor="background-primary"
          >
            <Box as={ListBox} overflowX="auto" maxHeight={rem(200)} paddingBottom={rem(8)}>
              <VStack as={Section} alignItems="stretch" gap={0} width="100%">
                <Text as={Header} textStyle="xsmall" padding={`${rem(16)} ${rem(16)} ${rem(8)}`} color="text-tertiary">
                  Select a purpose or create a new one
                </Text>
                {purposes.map((purpose) => (
                  <SelectItem
                    key={purpose.text}
                    category={category}
                    onUpdateCategory={onUpdateCategory}
                    isMenuOpen={isMenuOpen}
                    {...purpose}
                  />
                ))}
              </VStack>
            </Box>
          </Box>
        ) : (
          <VisuallyHidden>
            <Box as={Popover}>
              <Text as={Header}>There are no purposes to select, enter a purpose to create a new one</Text>
            </Box>
          </VisuallyHidden>
        )}
      </ComboBox>
    );
  },
);

export default PurposeSelect;
