import { IconChevronDown } from '@/theme/icons';
import rem from '@/utils/rem';
import { Box, HStack, Link, Text, VStack, forwardRef } from '@chakra-ui/react';
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom';

type RRINavItemProps = {
  label: string;
  icon: React.FunctionComponent;
  isDropdown?: boolean;
  isActive?: boolean;
};

type RRINavLinkProps = RRINavItemProps & {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const RRINavItem = forwardRef(
  ({ label, icon: Icon, isDropdown = false, isActive = false, ...props }: RRINavItemProps, ref) => (
    <VStack
      ref={ref}
      position="relative"
      alignItems={{ base: 'flex-start', lg: 'center' }}
      padding={rem(16)}
      {...props}
    >
      <HStack className="inner">
        <Box as={Icon} color={isActive ? 'cyan.400' : 'text-primary'} aria-hidden />

        <Text
          as="span"
          // textStroke is a work-around to make the font appear bold without causing a layout shift
          // and then having to set specific widths on the nav item. This solution means the nav can be
          // slightly more responsive
          sx={{ WebkitTextStroke: isActive ? '0.035em' : 'initial' }}
          textStyle={{
            base: 'medium',
            xl: 'large',
          }}
          whiteSpace="nowrap"
        >
          {label}
        </Text>
        {isDropdown && <Box as={IconChevronDown} aria-hidden />}
      </HStack>

      <Box className="global-menu__inner" aria-hidden />
    </VStack>
  ),
);

export const RRINavLink = forwardRef(({ icon, href, label, onClick, ...props }: RRINavLinkProps, ref) => {
  const { pathname } = useLocation();
  const path = useResolvedPath(href);
  const isActive = pathname === href || (href !== '/' && pathname.startsWith(path.pathname));

  return (
    <Link
      className={`global-menu ${isActive ? 'active' : ''}`}
      ref={ref}
      as={NavLink}
      display="block"
      width="full"
      _hover={{
        textDecoration: 'none',
      }}
      onClick={onClick}
      to={href}
      {...props}
    >
      <RRINavItem label={label} icon={icon} isActive={isActive} />
    </Link>
  );
});

export default RRINavLink;
