import { DAY, WEEK } from '@/constants/calendar';
import { IconCalendar, IconClose } from '@/theme/icons';
import { formatDateLabelByPlan } from '@/utils/calendar';
import rem from '@/utils/rem';
import { Box, Button, Flex, HStack, Icon, StyleProps, Text, forwardRef } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Ref } from 'react';

export type DateTimePickerPlaceholderProps = {
  placeholder?: string;
  isDatePickerOpen: boolean;
  scheduledDate: Date | null;
  scheduledTime?: Date | null;
  tabIndex: typeof DAY | typeof WEEK;
  onDatePickerOpen: () => void;
  onClickUnplan: () => void;
  border?: StyleProps['border'];
};

function DateTimePickerPlaceholder(
  {
    placeholder = 'Select Date',
    isDatePickerOpen,
    scheduledDate,
    scheduledTime,
    tabIndex,
    onDatePickerOpen,
    onClickUnplan,
    border,
  }: DateTimePickerPlaceholderProps,
  ref: Ref<HTMLButtonElement>,
) {
  return (
    <Flex
      alignItems="center"
      gap={rem(8)}
      width="fit-content"
      height={rem(28)}
      border={border}
      borderRadius={rem(4)}
      _hover={{ backgroundColor: 'background-quaternary' }}
      aria-label="Select a date range"
      backgroundColor="background-primary"
      paddingX={rem(8)}
    >
      <Button
        ref={ref}
        height={rem(28)}
        padding="0"
        background="transparent"
        _hover={{ background: 'transparent' }}
        onClick={onDatePickerOpen}
      >
        <HStack color={isDatePickerOpen ? 'gray.800' : 'gray.400'} spacing="2">
          <Box width={rem(14)} height={rem(14)}>
            <Box as={IconCalendar} width={rem(14)} height={rem(14)} color="text-primary" />
          </Box>
          {scheduledDate ? (
            <Text textStyle="small" color="text-primary">
              {scheduledTime
                ? format(new Date(scheduledTime), "E, MMM dd 'at' hh:mm a")
                : formatDateLabelByPlan(tabIndex, scheduledDate)}
            </Text>
          ) : (
            <Text textStyle="small" color="text-primary">
              {placeholder}
            </Text>
          )}
        </HStack>
      </Button>

      {scheduledDate && (
        <Button
          alignItems="center"
          justifyContent="center"
          display="flex"
          width={rem(16)}
          minWidth={rem(16)}
          height={rem(16)}
          borderRadius="full"
          cursor="pointer"
          backgroundColor="background-tertiary"
          onClick={onClickUnplan}
          variant="unstyled"
        >
          <Icon as={IconClose} width={rem(8)} height={rem(8)} />
        </Button>
      )}
    </Flex>
  );
}

const ForwardedDateTimePickerPlaceholder = forwardRef(DateTimePickerPlaceholder);

export default ForwardedDateTimePickerPlaceholder;
