import ActionModal from '@/components/ActionModal';
import NavbarGlobal from '@/components/NavbarGlobal';
import { actionsKeys } from '@/gql/actions/keys';
import { INSERT_CATEGORY_ONE } from '@/gql/category';
import { categoriesKeys } from '@/gql/category/keys';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import useWeeklyPlan from '@/services/plans/hooks/useWeeklyPlan';
import { useUser } from '@/services/user/hooks';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { usePeopleStore } from '@/stores/usePeopleStore';
import { addActionToCache, deleteAction } from '@/utils/action';
import { showSecondaryNavItemsBasedOnLocation } from '@/utils/index';
import rem from '@/utils/rem';
import { Container, ContainerProps, Flex, Spinner, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { ReactNode, Suspense, lazy, memo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const MySecondaryNavbar = lazy(() => import('@/components/SecondaryNavbar'));

const withPlaceholderPages = [
  RoutesList.ProjectsPage,
  RoutesList.PeoplePage,
  RoutesList.ProjectDetailPage,
  RoutesList.PersonPage,
  RoutesList.CategoryManagerDetailsPage,
];

type Props = {
  children: ReactNode;
  pageTitle: string;
  editActionModalTooltip?: string;
  rightSectionElements?: JSX.Element;
  forceShowSecondaryNav?: boolean;
  paddingTop?: ContainerProps['paddingTop'];
} & ContainerProps;

const DashboardLayout = ({
  children,
  pageTitle,
  editActionModalTooltip = undefined,
  rightSectionElements,
  forceShowSecondaryNav = false,
  paddingTop = rem(16),
  ...rest
}: Props) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const params = useParams();

  const showSecondaryNavBar = showSecondaryNavItemsBasedOnLocation(location)?.showSecondaryNavBar;

  const updateSearchQuery = usePeopleStore((state) => state.updateSearchQuery);
  const updateFilterSelected = usePeopleStore((state) => state.updateFilterSelected);

  const { opened: isActionModalOpen, setActionModalOpen, data, createLeverageModalOpen } = useActionModal();

  const { data: user } = useUser();

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const withPlaceholder = params?.id
    ? withPlaceholderPages.findIndex((page) => page.replace(/:id/, params.id ?? '') === location.pathname) !== -1
    : withPlaceholderPages.includes(location.pathname);

  // !TODO: this should go somewhere else
  useCategories({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (isEmpty(data?.category)) {
        createCategory.mutate();
      }
    },
  });

  const createCategory = useMutation({
    mutationFn: () =>
      fetchData(INSERT_CATEGORY_ONE, {
        name: 'Uncategorized',
        color: 'gray.300',
        id: uuidv4(),
        icon: 'uncategorized',
        ninetyDayGoal: null,
        oneYearGoal: null,
        // secondaryPurposes: null,
        selectedImageUrl: null,
        ultimatePurpose: null,
        ultimateVision: null,
        role: null,
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries(categoriesKeys.list);
      await queryClient.invalidateQueries(actionsKeys.list);
    },
  });

  useWeeklyPlan({ selectedDate });

  useEffect(() => {
    if (!location.pathname.includes('/people')) {
      updateSearchQuery('');
      updateFilterSelected('all');
    }
  }, [location.pathname, updateFilterSelected, updateSearchQuery]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Flex
        as="header"
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
        position="sticky"
        zIndex="1201" // --chakra-zIndices-banner + 1
        top={0}
        width="full"
        boxShadow="2px 2px 9px 0px rgba(0, 0, 0, 0.08)"
        transition="right 600ms cubic-bezier(0.65, 0.26, 0, 1.1)"
      >
        <VStack alignItems="flex-start" gap={0} width="full">
          <NavbarGlobal />

          {showSecondaryNavBar && !forceShowSecondaryNav && (
            <Suspense
              fallback={
                <Flex justifyContent="center" width="full">
                  <Spinner size="sm" />
                </Flex>
              }
            >
              <MySecondaryNavbar rightSectionElements={rightSectionElements} />
            </Suspense>
          )}
        </VStack>
      </Flex>

      <Container
        as="main"
        overflowY="scroll"
        maxWidth="full"
        paddingTop={paddingTop}
        background="background-primary"
        transition="margin-left 600ms cubic-bezier(0.65, 0.26, 0, 1.1)"
        paddingX={rem(16)}
        {...rest}
        sx={{
          '@media print': {
            padding: rem(16),
            overflowY: 'visible',
          },
        }}
      >
        {children}
      </Container>

      {(isActionModalOpen || createLeverageModalOpen) && (
        <ActionModal
          isOpen={isActionModalOpen}
          onClose={() => setActionModalOpen(false, undefined)}
          onCreateAction={addActionToCache}
          withPlaceholder={withPlaceholder}
          readonlyTooltipLabel={editActionModalTooltip}
          onDeleteAction={deleteAction}
          {...data}
        />
      )}
    </>
  );
};

export default memo(DashboardLayout);
